@import "../../styles/index.scss";

.awards {
    margin: 80px 0;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    background-color: $white;

    h3 {
        color: $black;
        @include breakpoint(md) {
            @include t-h3;
        }
    }

    @include breakpoint(md) {
        margin: 150px 0;
        padding: 60px;
    }

    @include breakpoint(lg) {
        padding: 108px;
        margin: 180px 0;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &-clutch, &-manifest {
            display: flex;
            flex-direction: column;
            gap: 12px;

            @include breakpoint(lg) {
                gap: 24px;
            }

            &-title {
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;
                color: $black;

                a {
                    align-items: center;
                    text-align: center;
                    display: flex;
                }
                svg {
                    width: 16px;
                    height: 16px;
                    path {
                        stroke: $grey;
                    }

                    @include breakpoint(md) {
                        width: 24px;
                        height: 24px;
                    }

                    &:hover {
                        path {
                            stroke: $black;
                        }
                    }
                }
                h4 {
                    @include breakpoint(md) {
                        @include t-s2;
                    }
                }
            }

            &-list {
                display: flex;
                flex-direction: row;
                gap: 12px;
                flex-wrap: wrap;

                @include breakpoint(lg) {
                    gap: 24px;
                }
            }
        }
    }
}