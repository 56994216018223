@import '../../styles/index.scss';

.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    color: $white;
    text-align: center;

    @include breakpoint(lg) {
        margin-top: 50px;
    }

    &-divider {
        display: block;
        margin-top: 82px;
        color: $lightGrey;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items:center;

        @include breakpoint(md) {
            flex-direction: row;
        }

        @include breakpoint(lg) {
            max-width: 1440px;
            margin: 0 auto;
        }

        &-icons {
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding: 40px 0;

            @include breakpoint(md) {
                width: 30%;
            }

            @include breakpoint(lg) {
                width: 35%;
            }

            &-logo {
                img {
                    border-radius: 50%;
                    box-shadow: 0 0 2rem rgba(236, 236, 236, 0.50);
                    width: 132px;
                    height: 132px;
                }
            }

            &-social {
                flex-wrap: nowrap;
                    img {
                        margin: 10px;
                        opacity: 0.7;
                    }
                }
        }

        &-navigation {
            padding: 40px 0;
            display: flex;
            flex-direction: column;
            gap: 40px;

            @include breakpoint(md) {
                flex-direction: row;
                gap: 24px;
                width: 70%;
                justify-content: space-evenly;
            }

            @include breakpoint(lg) {
                width: 65%;
            }

            &-block {
                display: flex;
                flex-direction: column;
                gap: 20px;
                text-align: center;

                @include breakpoint(md) {
                    text-align: left;
                }

                &-title {
                    color: $white;
                }
                &-link {
                    color: $lightGrey;
                    display: block;
                    text-decoration: none;

                    @extend .clickable-link-black-background;
                }
                
            }

        }

        hr {
            border: 0;
            width: 100%;
            border-top: 1px solid $white;
            margin-left: 0;
            margin-right: 0;
            @include breakpoint(md) {
                width: 0;
                border-left:  1px solid $white;
            }
        }

        &-vertical {
            @include breakpoint(md) {
                height: 190px;
                margin: auto;
            }
            &-small {
                display: none;

                @include breakpoint(md) {
                    display: inline;
                    margin: 70px auto;
                    height: 25px;
                }
            }
        }
    }

    &-copyright {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        margin-bottom: 8px;
        p {
            color: $white;
            opacity: 0.5;
        }

        @include breakpoint(md) {
            margin-top: 24px;
            margin-bottom: 24px;

            p {
                @include t-s-14;
            }
        }
    }
}