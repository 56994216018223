@import "../../styles/index.scss";

.error {
    &-content {
        margin: auto;
        text-align: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 306px;

        @include breakpoint(md) {
            width: 584px;
        }

        &-code {
            color: $white;
            font-family: PP Hatton;
            font-size: 100px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;  
        }

        h2 {
            color: $white;
        }
        &-message {
            color: $white;
            margin-top: 30px;
            margin-bottom: 45px;
            
            @include breakpoint(md) {
                margin-bottom: 39px;
            }
        }
        &-button {
            border: 1px solid var(--light, #EFEFEF);
            width: max-content;
            padding: 10px 70px;

            a {
                text-transform: capitalize;
                color: $white;
            }

            &:hover {
                background-color: $white;
                a {
                    color: $dark;
                }
            }
        }
    }
}