/* base */
@import "vars";
@import "fonts";
@import "typography";
@import "breakpoints";


h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

button {
    outline: none;
    background: transparent;
    border: none;
    padding: 0;
}

input,
button {
    -webkit-tap-highlight-color: transparent;
}

a {
    text-decoration: none;
}

div {
    box-sizing: border-box;
}

// Remove the blue highlight of button on mobile
a:active,
button:active,
div:active {
    -webkit-tap-highlight-color: transparent;
}

.desk {
    display: none;

    @include breakpoint(md) {
        display: block;
    }
}

.mob {
    display: block;

    @include breakpoint(md) {
        display: none;
    }
}

.uppercase {
    text-transform: uppercase;
}

.hov-icon:hover {
    img {
        color: $white;
        opacity: 1;
    }
}

.hov-get-in-touch-black:hover {
    opacity: 0.8;
}

.hov-get-in-touch-white:hover {
    opacity: 0.6;
}

.hov-learn-more:hover {
    opacity: 0.6;
}

.swiper-button-next {
    margin-top: -562px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    position: absolute;
    right: 0;
    z-index: 10;
    cursor: pointer;
    &:hover {
        background-color: $light;
    }

    @include breakpoint(lg) {
        width: 56px;
        height: 56px;
    }
}

.swiper-button-previous {
    margin-top: -562px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    position: absolute;
    left: 0;
    z-index: 10;
    cursor: pointer;
    &:hover {
        background-color: $light;
    }

    @include breakpoint(lg) {
        width: 56px;
        height: 56px;
    }
}

.base-link {
    text-decoration: underline;
    color: $grey;
    @include t-link;

    @include breakpoint(lg) {
        font-size: 16px;
    }

    &-upper {
        @extend .base-link;
        text-transform: uppercase;
    }
}

.cta-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    text-align: right;
    cursor: pointer;
    text-transform: uppercase;
    @include t-s-14;

    @include breakpoint(md) {
        @include t-s-16;
    }

    @include breakpoint(lg) {
        @include t-s-18;
    }
}

.clickable-link-black-background {
    color: $lightGrey;

    &:hover {
        color: $white;

        svg {
            path {
                stroke-opacity: 1;
                stroke: $white;
            }
        }
    }
}

.clickable-link-white-background {
    color: $grey;

    &:hover {
        color: $black;

        svg {
            path {
                stroke: $black;
                stroke-opacity: 1;
            }
        }
        
    }
}
