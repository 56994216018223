@import "../../styles/index.scss";

.video-block {
    position: relative;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;

    @include breakpoint(md) {
        padding: 60px 0px;
    }

    @include breakpoint(lg) {
        padding: 80px 0px;
    }

    svg {
        position: absolute;
        cursor: pointer;
        path {
            fill: $white;
        }
        @include breakpoint(md) {
            right: 138px;
            bottom: 146px;
            path {
                fill: $dark;
            }
        }
    }

    iframe {
        width: 100%;
        height: auto;
        min-height: 212px;
        object-fit: cover;
        border: none;
        @include breakpoint(sm) {
            height: 330px;
        }
        @include breakpoint(md) {
            height: 600px;
        }
        @include breakpoint(lg) {
            height: 755p x;
        }
    }
}
