@import "../../styles/index.scss";

.case-template-feedback{
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: $black;

    @include breakpoint(md) {
        gap: 50px;

        h3 {
            @include t-h-34;
        }
    }

    @include breakpoint(lg) {
        gap: 80px;
    }

    &-content {
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;

        @include breakpoint(lg) {
            flex-direction: row;
            gap: 0px;
        }

        hr {
            border: none;
            background-color: #757579;
            color: #757579;

            @include breakpoint(lg) {
                width: 1px;
                height: 192px;
                margin: 0 80px;
            }
        }

        &-client {
            display: flex;
            flex-direction: row;
            gap: 24px;

            @include breakpoint(lg) {
                gap: 32px;
                width: 50%;
            }

            hr {
                height: 1px;
                width: 22px;
                margin: 12px 0;
            }

            &-image {
                img {
                    width: 100px;
                    height: 100px;

                    @include breakpoint(lg) {
                        width: 192px;
                        height: 192px;
                    }
                }
            }

            &-info {
                p {
                    color: $grey;
                }

                a {
                    color: $grey;
                    text-decoration-line: underline;
                }

                @include breakpoint(lg) {
                    p, a {
                        @include t-s-18;
                    }

                    h4 {
                        @include t-s1;
                    }
                }

                &-desc {
                    display: flex;
                    flex-direction: row;

                    &-company {
                        color: $black !important;
                        font-weight: 400;
                    }
                }
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: $grey;

            @include breakpoint(lg) {
                width: 50%;
                p {
                    @include t-s-18;
                }
            }
        }
    }
}