@import "../../styles/index.scss";

.rounded-list {
    margin: 0 16px;
    padding: 60px 0px;
    color: $lightGrey;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    @include breakpoint(md) {
        margin: 0;
        padding: 100px 70px;
        gap: 60px;
    }

    @include breakpoint(lg) {
        gap: 80px;
        padding: 100px 215px;
    }

    h3 {
        color: $white;
        @include breakpoint(md) {
            @include t-h3;
        }
    }

    &-items {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        justify-content: center;
    }

    &-item {
        padding: 10px 20px;
        border-radius: 50px;
        white-space: nowrap;
        border: 1px solid $lightGrey;
        @include breakpoint(md) {
            padding: 15px 40px;
            @include t-b1;
        }
    }
}
