@import "../../styles/index.scss";

.send-email {
    &-form {
        display: contents;
    }

    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    gap: 24px;
    align-items: flex-start;
    background: $white;

    @include breakpoint(md) {
        padding: 60px;
    }

    @include breakpoint(lg) {
        padding: 80px;
        height: 747px;
        width: 605px;
    }

    &-field {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        &-label {
            color: $grey;
            font-family: "MacPaw Fixel";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            transition: color 0.3s ease;

            @include breakpoint(lg) {
                font-size: 16px;
            }
        }

        &-input {
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 10px;
            align-self: stretch;

            border: 0.5px solid $grey;
        }
       
        &:focus-within &-label {
            color: black;
        }

        &-textarea {
            min-height: 232px;
            padding: 12px 16px;
            border: 0.5px solid $grey;
            white-space: pre-wrap;

            font-family: "MacPaw Fixel";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;

            resize: none;
              
            @include breakpoint(lg) {
                min-height: 208px;
            }

            &:focus {
                color: $black;
            }
        }

        &-textarea:focus, input:focus {
            border: 0.5px solid $black;
            outline: 0;
            outline-color: transparent;
            outline-style: none;
        }
    }

    &-agreement{
        display: flex;
        flex-direction: row;
        gap: 16px;
        color: $grey;

        a {
            text-decoration: underline;
            color: $black;
        }

        @include breakpoint(lg) {
            gap: 8px;
        }

        input[type="checkbox"]{
            accent-color: $black;
            &:hover {
                accent-color: $grey;
            }
        }     
    }
    
    .loading {
        background-color: $black !important;
        cursor: auto !important;
    }
    button {
        background-color: $dark;
        padding: 16px 32px;
        color: $white;
        text-transform: uppercase;
        width: 100%;

        &:disabled, &:disabled:hover  {
            background-color: $grey;
            cursor: auto;
        }

        &:hover {
            cursor: pointer;
            background-color: $black;
        }

        span {
            display: flex;
            height: 22px;
            text-align: center;
            align-items: center;
            justify-content: center;        
        }
    }

    &-success {
        display: flex;
        flex-direction: column;
        padding: 40px 20px;
        gap: 24px;
        align-items: center;
        background: $white;
        justify-content: center;
        height: 700px;

        @include breakpoint(md) {
            padding: 60px;
        }

        @include breakpoint(lg) {
            padding: 80px;
            gap: 32px;
            height: 747px;
            width: 605px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 8px;
            
            p {
                font-family: "MacPaw Fixel";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 160%;
                color: $black;

                @include breakpoint(md) {
                    font-size: 22px;
                }
            }

            span {
                color: $grey;
                @include breakpoint(md) {
                    @include t-b4;
                }
            }
        }
    }
}
