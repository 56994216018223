// Brand colors
$white: #ffffff;
$light: #efefef;
$dark: #27272d;
$black: #0e0e0e;

// Other colors

$darkWhite: rgba(255, 255, 255, 0.10000000149011612);
$darkOpacity: rgba(14, 14, 14, 0.3);

$whiteOpacity: rgba(255, 255, 255, 0.7);
$grey: #757579;
$lightGrey: #B7B7B7;