@import "../../styles/index.scss";

.privacy-message {
    position: fixed;
    bottom: 0;
    z-index: 999;
    background-color: $black;
    border: 1px solid $lightGrey;
    margin: 0 20px 20px 20px;

    @include breakpoint(md) {
        width: 45%;
        margin-left: 50px;
        margin-bottom: 20px;
    }

    @include breakpoint(lg) {
        margin-left: 80px;
        margin-bottom: 40px;
    }

    svg {
        top: 20px;
        right: 20px;
        position: absolute;
    }

    &-content {
        margin: 0 auto;
        padding: 40px;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-items: flex-start;

        &-text {
            display: flex;
            flex-direction: column;
            gap: 16px;

            h2 {
                text-transform: uppercase;
                color: $white;
        
                @include breakpoint(lg) {
                    @include t-s-18;
                };
            }
        
            p {
                color: $lightGrey;
            }
        }
    
        a {
            color: $lightGrey;
            text-decoration: underline;
            &:hover {
                color: $white;
            }
        }

        button {
            background-color: $white;
            padding: 12px 24px;
            border: 1px solid $light;
            cursor: pointer;
            color: $black;
            font-family: "MacPaw Fixel";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;

            &:hover,
            :active,
            :focus {
                background-color: $light;
                color: $dark;
            }
        }
    }
}
