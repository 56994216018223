@import "../../styles/index.scss";

.grid-layout {
    margin: 0 20px;

    @include breakpoint(sm) {
        margin: 0 30px;
    }

    @include breakpoint(md) {
        margin: 0 50px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        max-width: 1280px;
    }
}