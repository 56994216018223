@import "../../styles/index.scss";

.big-num-list {
    background-color: $white;
    margin: 0 20px;
    padding: 60px 0 80px 0;
    color: $dark;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint(sm) {
        margin: 0;
    }

    @include breakpoint(md) {
        padding: 120px 0 100px 355px;
        gap: 60px;

        h3 {
            padding-bottom: 30px;
            @include t-s3;

            @include breakpoint(md) {
                @include t-h3;
            }
        }
    }

    @include breakpoint(lg) {
        padding: 180px 0 148px 355px;
        gap: 80px;
    }


    &-block {
        hr {
            background: $lightGrey;
            margin:  40px 0;
            height: 1px;
            border: none;

            @include breakpoint(md) {
                margin:  60px 0;
            }
        }

        &-item {
            display: flex;
            flex-direction: row;
            gap: 16px;
            @include breakpoint(md) {
                gap: 24px;
            }

            &-number {
                font-size: 36px;
                font-family: "PP Telegraf";
                font-style: normal;
                font-weight: 200;
                line-height: 100%;
                min-width: 80px;
                color: $white;

                @include breakpoint(md) {
                    padding-right: 0;

                    font-size: 90px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: 100%;
                    min-width: 120px;
                }

                @include breakpoint(lg) {
                    min-width: 150px;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                gap: 8px;

                @include breakpoint(md) {
                    gap: 16px; 
                }

                @include breakpoint(lg) {
                    gap: 24px;
                }
               
                h4 {
                    color: $white;

                    @include breakpoint(lg) {
                        @include t-s2;
                    }
                }

                p {
                    color: $lightGrey;
                    @include breakpoint(lg) {
                        font-family: "MacPaw Fixel";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                    }
                }
            }
        }
    }

    &-dark {
        background-color: $black;
        color: $white;
    }

    &.light {
        .big-num-list-block-item-number, h4 {
            color: $black;
        }

        h4 {
            @include breakpoint(md) 
            {
                width: 60%;
            }
        }

        p {
            color: $grey;
        }

        hr {
            background: $grey;
        }
    }
}
