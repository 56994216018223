@import "../../styles/index.scss";

.case-template-header {
    display: flex;
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center;    
    gap: 32px;

    @include breakpoint(md){
        flex-direction: row-reverse;
        gap: 20px;
    }

    &-image {
        display: flex;
        align-items: center;
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
    
            @include breakpoint(lg) {
                width: 520px;
                height: 450px;
            }
        }
    }

    &-content {
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        color: $black;

        h1 {
            margin-bottom: 16px;
            color: $grey;
            @include breakpoint(lg) {
                @include t-h-18;
                margin-bottom: 32px;
            }
        }

        h2 {
            margin-bottom: 32px;
            @include breakpoint(lg) {
                @include t-h-34;
                width: 80%;
            }
        }

        &-extra {
            display: flex;
            flex-direction: column;

            @include breakpoint(md){
                flex-direction: row;
            }

            &-industry, &-location, &-duration, &-service {
                display: flex;
                flex-direction: column;

                @include breakpoint(md) {
                    flex-direction: row;
                }

                h5 {
                    color: $grey;
                    margin-bottom: 4px;
                }

                hr {
                    color: $grey;
                    background-color: $grey;
                    border: none;
                    width: 20px;
                    height: 1px;
                    margin-left: 0;
                    @include breakpoint(md) {
                        margin: 0 18px;
                        width: 1px;
                        height: 42px;
                    }
                }
            }

            &-service {
                flex-direction: column;
                &-list {
                    display: flex;
                    flex-direction: column;
                }
            }

            &-industry {
                p {
                    white-space: pre;
                }
            }
        }
    }
}