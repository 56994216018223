@import "../../styles/index.scss";

.case-template-ratings {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: $black;
    width: 100%;

    @include breakpoint(md) {
        h3 {
            @include t-h-34;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include breakpoint(lg) {
            gap: 34px;
        }

        &-mark {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;

            &-stars {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 8px;

                &-number, .stars {
                    font-family: "MacPaw Fixel";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 32px */

                    .star {
                        width: 18px;
                        height: 18px;
    
                        @include breakpoint(lg) {
                            font-size: 24px !important;
                        }
                    }

                    @include breakpoint(lg) {
                        font-family: "PP Telegraf";
                        font-size: 38px;
                        line-height: 130%; /* 49.4px */
                    }
                }
            }

            @include breakpoint(lg) {
                flex-direction: row;
                gap: 15px;

                &-stars {
                    flex-direction: row;
                }

                a {
                    display: flex;
                    align-items: center;
                }
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            @include breakpoint(md) {
                flex-direction: row;
                column-gap: 80px;
            }

            hr {
                width: 100%;
                height: 1px;
                border: none;
                background: $grey;

                margin: 16px 0;
            }
            & > &-points {
                flex: 33.33%;
            }

            &-points { 
                width: 100%;
                &-point {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &-text {
                        display: flex;
                        flex-direction: column;

                        &-title {
                            color: $black;
                        
                            @include breakpoint(lg) {
                                @include t-s-24;
                            }
                        }

                        &-subtitle {
                            color: $grey;

                            @include breakpoint(lg) {
                                @include t-s-18;
                            }
                        }
                    }

                    &-mark {
                        font-family: "MacPaw Fixel";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%; /* 32px */

                        @include breakpoint(lg) {
                            font-family: "MacPaw Fixel";
                            font-size: 28px;
                        }
                    }
                }
            }
        }
    }
}