@import '../../styles/index.scss';


.toolkit-dd-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-y: visible;

  &-label {
    color: $lightGrey;
    text-transform: uppercase;

    @include breakpoint(md) {
      @include t-s-14;
    }
  }

  &-react-select {
    &__control {
      box-shadow: none !important;
      cursor: pointer !important;
      border-radius: 80px !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }

    &__indicator {
      color: $lightGrey !important;

      &:hover {

      }

      svg {
        width: 18px;
        height: 18px;

        @include breakpoint(md) {
          width: 24px;
          height: 24px;
        }
      }
      padding: 0 !important;
    }

    &__indicator:hover {
      color: white !important;
    }

    &__value-container {
      div {
        color: $white;
        text-transform: uppercase;
        @include t-s-12;

        @include breakpoint(md) {
          @include t-s-14;
        }
      }
    }

    &__menu {
      cursor: pointer !important;
      z-index: 5 !important;
      display: flex;
      flex-direction: column;
      gap: 24px;
      background-color: $black !important;
      width: 247px !important;
      padding: 24px;
      margin: 0 !important;
      border-radius: none !important;
      box-shadow: none !important;

      @include breakpoint(md) {
        gap: 16px;
        width: max-content !important;
      }
    }

    &__option {
      @extend .t-s-12;
      color: $lightGrey !important;
      background-color: $black !important;
      cursor: pointer !important;
      text-transform: uppercase;

      @include breakpoint(md) {
        @include t-s-14;
      }

      &--is-selected {
        @extend .t-s3;
        background-color: $black !important;
      }
    }

    &__menu-list {
      padding: 0 !important;
      background-color: $black !important;
    }

    &__menu-list:hover, &__menu-list:focus {
      background-color: $black !important;
    }

    &__option:hover, &__option:focus {
      color: $white !important;
      background-color: $black !important;
    }
  }
}
