@import "../../styles/index.scss";

.industry-template-services {
    position: relative;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint(sm) {
        margin: 0;
    }

    @include breakpoint(lg) {
        gap: 80px;
    }

    h3 {
       color: $white;
       text-transform: uppercase;
       
        @include breakpoint(lg) {
            @include t-h3;
        }
    }

    &-items {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 24px;

        @include breakpoint(md) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-item {
            display: flex;
            flex-direction: column;
            gap: 32px;
            padding: 32px;
            border: 1px solid $grey;

            @include breakpoint(md) {
                flex-direction: row;
                gap: 48px;
                padding: 40px;
                flex-basis: calc(50% - 24px);

                svg {
                    margin: auto 0;
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                gap: 12px;

                @include breakpoint(md) {
                    width: 70%;
                }

                h4 {
                    color: $white;
                    text-transform: uppercase;
                    @include breakpoint(lg) {
                        @include t-h-24;
                    }
                }

                p {
                    color: $lightGrey;

                    @include breakpoint(lg) {
                        @include t-s-16;
                    }
                }
            }
        }
    }
}