@import "../../styles/index.scss";

.case-template-challenges-solutions{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $black;

    hr {
        margin: 80px 0px;
        height: 1px;
        width: 100%;
        background: $grey;
        border: none;
        @include breakpoint(md) {
            width: 1px;
            height: inherit;
        }

    }

    @include breakpoint(md) {
        flex-direction: row;

        h3 {
            @include t-h-34;
        }

        hr {
            margin: 0 50px;
        }
    }

    @include breakpoint(lg) {
        hr {
            margin: 0 80px;
        }
    }

    @include breakpoint(lg) {
        //gap: 40px;
    }

    &-solutions, &-challenges {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: -webkit-fill-available;

        &-list {
            display: flex;
            flex-direction: column;
            gap: 10px;
    
            color: $grey;

            &-item {
                display: flex;
                flex-direction: row;
                gap: 24px;

                p:first-child {
                    margin-top: 10px;

                    @include breakpoint(sm) {
                        margin-top: 4px;
                    }

                    @include breakpoint(md) {
                        margin-top: 0px;
                    }
                }
            }

            p {
                @include breakpoint(md) {
                    @include t-s-18;
                }
            }
        }
    }
}