@import "../../styles/index.scss";

.technologies-list {
    width: 100%;
    text-align: center;

    &-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 50px;
        gap: 32px;
        align-items: flex-end;
        @include breakpoint(sm) {
            padding: 0 60px;
        }

        @include breakpoint(md) {
            padding: 0;
            gap: 40px;

            margin-top: -60px;
        }

        @include breakpoint(lg) {
            flex-wrap: nowrap;
        }

        &-container {

            &-title {
                display: none;
            }
            
            @include breakpoint(md) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;
                position: relative;

                &-title {
                    top: -50px;
                    color: $black;
                    width: 100%;
                    text-align: center;
                    //transition: 0.8s;

                    display: flex;
                    padding: 8px 10px 6px 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px;
                    background-color: $black;
                    color: $white;
                    visibility: hidden;

                    @include breakpoint(md) {
                        top: -50px;
                    }
                }
                &:hover > &-title {
                    visibility: visible;
                }
            }
        }
    }
}

.technologies-horizontal {
    h3 {
        white-space: nowrap;
    }
    @include breakpoint(md) {
        display: flex;
        gap: 120px;
        align-items: center;
        .technologies-list-icons {
            width: fill-available;
            justify-content: space-between;
        }
    }
}
