@import "../../styles/index.scss";

.services-cards {
    margin-bottom: 30px;
    color: $white;

    &-header {
        display: flex;
        flex-direction: row;
        margin: 60px 0 30px 0px;
        justify-content: space-between;
        align-items: center;

        h3 {
            text-transform: uppercase;
            @include breakpoint(lg) {
                @include t-h-34;
            }
        }

        a {
            font-family: MacPaw Fixel;
            text-transform: uppercase;
            display: inline-flex;
            align-items: center;
            gap: 8px;

            @include breakpoint(lg) {
                font-size: 18px;
            }
        }

        @include breakpoint(md) {
            margin: 90px 0 48px;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include breakpoint (sm) {
            flex-direction: row;
        }
        
        @include breakpoint(lg) {
            padding: 0;
            justify-content: flex-start;
        }
    }
}

.card {
    border: 1px solid $lightGrey;
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    z-index: 2;

    &:hover {
        cursor: pointer;
        background-color: #262626;
    }

    &-image {
        width: 100%;
        height: 214px;
        object-fit: cover;
        border-bottom: 1px solid $lightGrey;
        z-index: 1;

        @include breakpoint(md) {
            height: 300px;
        }

        @include breakpoint(lg) {
            height: 400px;
        }
    }

    &-text {
        padding: 0 24px 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        h4 {
            color: white;
        }

        p {
            color: $whiteOpacity;
        }

        @include breakpoint(md) {
            padding: 0 32px 32px;
        }
        @include breakpoint(lg) {
            h4 {
                width: 50%;
                @include t-h-24;
            }
            p {
                @include t-b3;
            }
        }
    }
}
