@import "../../styles/index.scss";

.service-template-services {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0 20px;

    @include breakpoint(sm) {
        margin: 0;
    }

    h3 {
        text-transform: uppercase;
        color: $white;

        @include breakpoint(md) {
            @include t-h-34;
        }
    }

    @include breakpoint(md) {
        gap: 80px;
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include breakpoint(sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @include breakpoint(md) {
            gap: 60px;
        }

        @include breakpoint(lg) {
            gap: 80px;
        }

        &-item {
            display: flex;
            flex: 1 1 40%;
            flex-direction: column;
            gap: 12px;
            padding-left: 32px;
            border-left: 1px solid $lightGrey;
            flex-basis: 50%;

            @include breakpoint(md) {
                gap: 24px;
                flex: none;
                padding-left: 40px;
                flex-basis: calc(49% - 24px);
            }

            @include breakpoint(lg) {
                flex-basis: calc(48.7% - 24px);
            }

            p {
                color: $lightGrey;

                @include breakpoint(lg) {
                    font-family: "MacPaw Fixel";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                }
            }
            h4 {
                color: $white;
                @include breakpoint(lg) {
                    @include t-s3;
                }
            }
        }
    }
}