@import "../../styles/index.scss";

.one-case {
    display: flex;
    flex-direction: column;
    cursor: url('../../assets/images/casesCardHover.svg') 40 25, auto;;
    @include breakpoint(md){
        flex-direction: row;

        &.reverse {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }

    &-image {
        display: flex;
        align-items: center;
        img {
            width: 100%;
            height: auto;
            object-fit: cover;

            @include breakpoint(md) {
                width: 350px;
                height: 100%;
            }
    
            @include breakpoint(lg) {
                width: 411px;
                height: 350px;
            }
        }
    }

    &:hover .one-case-content {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), padding-box !important;
    }

    &-content {
        display: flex;
        padding: 40px 24px;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        color: $white;
        width: -webkit-fill-available;

        background: initial;
        transition: background 0.3s ease;

        @include breakpoint(md) {
            padding: 24px;
        }

        @include breakpoint(lg){
            padding: 0px 40px;
        }

        h4 {
            margin-bottom: 16px;
            color: $whiteOpacity;
            @include breakpoint(lg) {
                @include t-h-16;
                margin-bottom: 32px;
            }
        }

        h3 {
            margin-bottom: 32px;
            @include breakpoint(lg) {
                @include t-h-24;
                width: 70%;
            }
        }

        &-extra {
            display: flex;
            flex-direction: column;

            @include breakpoint(md){
                flex-direction: row;
            }

            &-industry, &-location, &-duration, &-service {
                display: flex;
                flex-direction: column;

                @include breakpoint(md) {
                    flex-direction: row;
                }

                h5 {
                    color: $whiteOpacity;
                    margin-bottom: 4px;
                }

                hr {
                    border: none;
                    color:  $whiteOpacity;
                    background-color:  $whiteOpacity;
                    width: 20px;
                    height: 1px;
                    margin-left: 0;
                    @include breakpoint(md) {
                        margin: 0 18px;
                        width: 1px;
                        height: 42px;
                    }
                }
            }

            &-service {
                flex-direction: column;
                &-list {
                    display: flex;
                    flex-direction: column;
                }
            }
            &-industry {
                p {
                    white-space: pre;
                }
            }
        }
    }
}