@import "../../styles/index.scss";

.other-cases {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: $white;
    background-color: $black;
    margin: 80px 20px;

    @include breakpoint(sm) {
        margin: 80px 30px;
    }

    @include breakpoint(md) {
        margin: 80px 25px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        max-width: 1336px;
        margin-top: 120px;
        margin-bottom: 180px;
    }

    .swiper-button-next,
    .swiper-button-previous {
        margin-top: unset;
        top: 130px;
        @include breakpoint(md) {
            top: 115px;
        }

        @include breakpoint(lg) {
            top: 135px;
        }
    }

    .one-case-link {
        color: $white;

        svg {
            path {
                fill: $white;
            }
        }
    }

    .swiper {
        margin-left: 0;
        margin-right: 0;
    }
    
    .swiper-pagination {
        gap: 8px;
        display: flex;
        margin-top: 32px;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: transparent;
        border-radius: 5px;
        border: 1px solid transparent;
        background: $whiteOpacity;
        opacity: 1;
        cursor: pointer;
    }

    .swiper-pagination-bullet-active {
        width: 6px;
        height: 6px;
        transition: width 0.5s;
        border-radius: 5px;
        background: $white;
        border: 1px solid transparent;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include breakpoint(md) {
            p {
                @include t-h3;
            }
        }

        @include breakpoint(lg) {
            h3 {
                @include t-h3;
            }
        }

        @include breakpoint(md) {
            padding: 0 25px;
        }

        @include breakpoint(lg) {
            padding: 0 28px;
        }
    }

    .one-case {
        height: 800px;

        @include breakpoint(sm) {
            height: 1010px;
        }
        @include breakpoint (md) {
            margin-left: 25px;
            width: 96%;
            height: auto;
        }

        @include breakpoint (lg) {
            margin-left: 28px;
            width: 95%;
        }
    }
}
