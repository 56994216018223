// breakpoint functions is used for different screen sizes

@mixin breakpoint($point) {
  @if $point == xs {
    @content;
  } @else if $point == sm {
    @media screen and (min-width: 720px) {
      @content;
    }
  } @else if $point == md {
    @media screen and (min-width: 1024px) {
      @content;
    }
  } @else if $point == lg {
    @media screen and (min-width: 1440px) {
      @content;
    }
  }
}
