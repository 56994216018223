@import "../../styles/index.scss";

.gallery {
    color: $white;
    margin-top: 80px;
    margin-bottom: 90px;
    overflow-x: hidden;
    @include breakpoint(md) {
        margin-bottom: 180px;
        margin-top: 180px;
    }
    &-header {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        margin: 0px 20px 40px 20px;

        @include breakpoint(sm) {
            margin: 0px 30px 40px 30px;
        }

        @include breakpoint(md) {
            flex-direction: row;
            justify-content: space-between;
            margin: 0 50px 80px 50px;

            a {
                color: $whiteOpacity;
            }

            h3 {
                text-transform: uppercase;

                @include breakpoint(md) {
                    @include t-h3;
                }
            }

            p {
                @include t-s5;
            }
        }

        @include breakpoint(lg) {
            margin: 0 auto;
            margin-bottom: 80px;
            max-width: 1280px;
        }
    }

    &-image {
        height: 144px;
        margin-right: 10px;
        border: 1px solid $lightGrey;
        @include breakpoint(md) {
            height: 320px;
            margin-right: 24px;
        }
    }

    &-link {
        color: $white;
    }

    &-scroller-wrapper {
        overflow: hidden;
        position: relative;
        margin-top: 5px;
        @include breakpoint(md) {
            height: 60px;
            margin-top: -50px;
        }
    
        @include breakpoint(lg) {
            height: 65px;
            margin-top: -60px;
        }
    }

    &-scroller {
        display: inline-flex;
        width: max-content;
        position: absolute;
        margin: 0 auto;
        overflow: hidden;

        @include breakpoint(md) {
            font-size: 50px;
        }
        .orb {
            color: $white;
            display: inline-block;
            margin-left: 12px;

            @include breakpoint(md) {
                font-size: 50px;
            }
        }
    }

    [data-controls="true"] {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: -10px;
        z-index: 10;
    }

    [data-controls="false"] {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: -5px;
    }

    &-button-previous {
        width: 45px;
        height: 45px;
        background-color: $white;
        left: 0;
        z-index: 10;
        cursor: pointer;
        display: none;
        &:hover {
            background-color: $light;
        }
        @include breakpoint(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
        }
        @include breakpoint(lg) {
            margin-top: 10px;

            width: 56px;
            height: 56px;
        }
    }

    &-button-next {
        width: 45px;
        height: 45px;
        background-color: $white;
        right: 0;
        display: none;
        z-index: 10;
        cursor: pointer;
        &:hover {
            background-color: $light;
        }
        @include breakpoint(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px; 
        }

        @include breakpoint(lg) {
            margin-top: 10px;

            width: 56px;
            height: 56px;
        }
    }

    .swiper-slide {
        min-width: fit-content;
        max-width: fit-content;
    }
}

@keyframes infiniteScroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
.scroller {
    width: 100%;
    overflow-x: hidden;
}
.horizontal-scrolling-items {
    display: flex;
    font-size: 50px;
    width: 2600px;
    animation-name: infiniteScroll;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    gap: 23px;
    @include breakpoint(md) {
        animation-duration: 30s;
    }
}
.horizontal-scrolling-items__item {
    white-space: nowrap;
    display: flex;
    gap: 23px;
    margin-top: 3px;
    color: $whiteOpacity;
    @include breakpoint(md) {
        p {
            font-size: 50px;
            font-family: PP Telegraf;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: uppercase;
        }
    }
}

.swiper-first {
    z-index: 2;
}
