.blank-layout {
    display: flex;
    flex-direction: column;
    //min-height: 100vh;

    &-content {
        flex-grow: 1;
    }
    &-footer {
        flex-grow: 2;
    }
}