@import "../../styles/index.scss";

.metrics-block {
    color: $white;
    margin: 80px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint(sm) {
        margin: 80px 0;
    }

    @include breakpoint(md) {
        gap: 60px;
        margin: 150px 0;
    }

    @include breakpoint(lg) {
        gap: 80px;
        margin: 180px 0;
    }

    h3 {
        @include breakpoint(md) {
            @include t-h3;
        }
    }

    &-items {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        @include breakpoint(md) {
            gap: 24px;
        }

        h3 {
            text-align: center;
            @include breakpoint(md) {
                @include t-s6;
            }
        }

        &-item {
            padding: 0 20px;
            border: 1px solid #ffffff;
            display: flex;
            flex-direction: column;
            height: 180px;;
            flex-wrap: wrap;
            width: 47.5%;
            align-items: center;
            justify-content: center;

            @include breakpoint(sm) {
                width: 48.5%;
            }

            @include breakpoint(md) {
                padding: 0px 40px;
                flex-wrap: nowrap;
                height: 411px;
                gap: 24px;
                width: 31.6%;
                justify-content: flex-start;
                padding-top: 5%;
            }

            @include breakpoint(lg) {
                width: 32%;
            }

            p {
                text-align: center;
                color: $white;
                @include breakpoint(sm) {
                    @include t-s-18;
                }
                @include breakpoint(md) {
                    @include t-s-24;
                }
            }
        }
    }

    &-number {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-family: MacPaw Fixel;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 132.5%;

        @include breakpoint(md) {
            color: $white;
            font-family: MacPaw Fixel;
            font-size: 148px;
            font-style: normal;
            font-weight: 600;
            line-height: 132.5%;
        }

        &-plus {
            position: static;
            right: 28px;
            top: -10px;
            color: $white;
            font-family: "MacPaw Fixel" !important;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;

            @include breakpoint(md) {
                font-size: 62px !important;
                right: 15px;
                top: -10px;
            }
        }
    }
}
