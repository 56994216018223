@import "../../styles/index.scss";
 
.one-testimonial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $whiteOpacity;
    padding: 24px;
    height: 410px;

    @include breakpoint(md) {
        padding: 40px;
        height: 330px;
    }

    &-description {
        color: $lightGrey;

        @include breakpoint(lg) {
            p {
                @include t-s-16;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: $white;

        h3 {
            @include breakpoint(md) {
                @include t-h-24;
            }
        }

        p {
            color: $lightGrey;
            @include breakpoint(lg) {
                @include t-s-16;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
            color: $whiteOpacity;
            @include breakpoint(md) {
                @include t-b3;
            }
        }

        svg {
            margin-top: -8px;
        }

        &-client {
            display: flex;
            flex-direction: row;
            gap: 24px;

            hr {
                display: none;

                @include breakpoint(md) {
                    display: inline;
                    height: 1px;
                    width: 22px;
                    margin: 12px 0;
                    color: $whiteOpacity;
                    background-color: $whiteOpacity;
                    border: none;
                }
            }

            &-image {
                img {
                    width: 80px;
                    height: 80px;
                    border-radius: 200px;

                    @include breakpoint(md) {
                        width: 100px;
                        height: 100px;
                    }
                }
            }

            &-info {
                display: flex;
                flex-direction: column;
                gap: 4px;
                justify-content: center;

                p {
                    color: $lightGrey;
                }

                a {
                    color: $lightGrey;
                    text-decoration-line: underline;
                }

                @include breakpoint(lg) { 
                    gap: 0;
                    p, a {
                        @include t-s-16;
                    }

                    h4 {
                        @include t-s2-m;
                    }
                }

                &-desc {
                    display: flex;
                    flex-direction: row;

                    @include breakpoint(sm) {
                        @include t-s-14;
                    }

                    @include breakpoint(lg) {
                        @include t-s-16;
                    }

                    &-company {
                        color: $white !important;
                        font-weight: 400;
                    }
                }
            }
        }

        &-rating {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            font-family: "PP Telegraf";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;

            @include breakpoint(md) {
                flex-direction: column;
                gap: 16px;
                align-items: flex-end;
                justify-content: center;
            }

            p {
                color: $white !important;
            }
        }
    }
}