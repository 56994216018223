@import "../../styles/index.scss";

.industries {
    display: flex;
    flex-direction: column;
    margin: 160px 0px 80px 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint(md) {
        margin: 160px 0px 120px 0px;
    }

    @include breakpoint(lg) {
        margin: 210px 0px 180px 0px;
        gap: 120px;
    }


    &-header {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include breakpoint(lg) {
            gap: 16px;
            p {
                font-family: "MacPaw Fixel";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }
        }
       
        &-desc {
            color: $lightGrey;

            @include breakpoint(sm) {
                width: 60%;
            }

            @include breakpoint(lg) {
                width: 64%;
            }
        }
        h1 {
            color: $white;
            font-family: "PP Telegraf";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: uppercase;

            @include breakpoint(sm) {
                @include t-h1;
            }
        }

        &-cta {
            display: flex;
            flex-direction: row;
            gap: 8px;
            padding-top: 40px;
            text-transform: uppercase;
            align-items: center;
            width: max-content; 
            cursor: pointer;

            @include breakpoint(md){
                padding-top: 60px;
            }

            @include breakpoint(lg) {
                gap: 12px;
                padding-top: 88px;

                a {
                    @include t-s-18;
                }

                svg {
                    width: 24px;
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include breakpoint(md) {
            gap: 60px;
        }

        @include breakpoint(lg) {
            gap: 80px;
        }
    }

}