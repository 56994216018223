@import "../../styles/index.scss";

.donate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1440px;
    margin: 0 auto;
    height: 100vh;

    &-content {
        display: flex;
        flex-direction: row;
        width: 95%;

        &-main {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            &-progress {
                border-radius: 10px;
                height: 25vh;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 50px;
                width: 100%;

                &-percent {
                    margin: auto;
                    width: fit-content;
                    position: relative;
                    font-size: 15vh;
                }
            }

            &-info {
                display: flex;
                flex-direction: row;
                align-items: center; 

                &-title {
                    display: flex;
                    flex-direction: column;
                    width: 75%;
                    align-items: center;

                    &-jar {
                        color: white;
                        font-size: 9vh;
                        margin-bottom: 25px;
                    }

                    &-money {
                        display: flex;
                        p {
                            color: white;
                            font-size: 20vh;
                        }
                    }
                }

                &-qr {
                    max-width: 30%;
                    display: flex;
                    align-items: center;
                    padding-left: 45px;

                    img {
                        object-fit: "fill";
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1275px) {
    .donate-content-main-info {
        flex-direction: column-reverse;
        &-title {
            width: 100%;
            &-jar {
                font-size: 3.8rem;
            }

            &-money {
                p {
                    font-size: 6rem;
                }
            }
        }

        &-qr {
            max-width: 50%;
        }
    }
    
    .donate-content-main-progress {
        height: 20vh;
        &-percent {
            font-size: 15vh;
        }
    }
}

@media screen and (max-width: 540px) {
    .donate-content-main-info {
        flex-direction: column-reverse;

        &-title {
            width: 100%;
            &-jar {
                font-size: 2.2rem;
            }

            &-money {
                p {
                    font-size: 4rem;
                }
            }
        }

        &-qr {
            padding-left: 0;
            max-width: 50%;
        }
    }

    .donate-content-main-progress {
        height: 20vh;
        &-percent {
            font-size: 12vh;
        }
    }
}
