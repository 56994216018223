@import "../../styles/index.scss";

.faq {
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin: 0 20px;

        @include breakpoint(sm) {
            margin: 0;
        }

    h3 {
        color: $white;
        text-transform: uppercase;

        @include breakpoint(lg) {
            @include t-h3;
        }
    }

    &-content {
        list-style: none;
        margin: 0;
        padding: 0;
        &-item {
          display: flex;
          flex-direction: column;

          hr {
            border: none;
            height: 1px;
            width: 100%;
            background-color: $lightGrey;
            margin: 0 0 40px 0;

            @include breakpoint(lg) {
              margin: 20px 0 60px 0;
            }
          }

          li {

            p:hover {
              color: $white;

              .arrow-toggle::before {
                content: '';
                border: solid $white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 5px;
              }
            }
            &.active {
              .answer {
                max-height: 380px !important;
                padding-bottom: 40px;
                transition: max-height 0.5s ease, padding-bottom 0.5s ease;
              }
        
              .question {
                color: $white;
                transition: color 0.5s ease;
              }
            }
          }
        
          .answer {
            color: $lightGrey;
            max-height: 0;
            overflow: hidden;
            padding-top: 40px;
            transition: max-height 0.5s ease, padding-bottom 0.5s ease;

            @include breakpoint(md) {
              @include t-s-16;
            }
          }
          .arrow-toggle {
            cursor: pointer;
            width: 21px;
            height: 21px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          
            @include breakpoint(md) {
              top: 50%;
            }
          
            &::before {
              content: '';
              border: solid $lightGrey;
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 5px;
              transform: rotate(-135deg);
            }
          
            &.collapsed::before {
              transform: rotate(45deg);
            }
          }
          
          .question {
            color: $lightGrey;
            text-transform: initial;
            position: relative;
            cursor: pointer;
            padding-right: 30px;
            transition: color 0.5s ease;

            @include breakpoint(md) {
              font-family: "PP Telegraf";
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              text-transform: uppercase;
            }
          }
        }
    } 
}
