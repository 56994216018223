@import "../../styles/index.scss";

.banner-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 5rem;

    @include breakpoint(md) {
        height: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @include breakpoint(lg) {
        height: 5rem;
    }
}

.banner-wrapper {
    position: absolute;
    display: flex;

    svg, img {
        max-width: 150px;
        height: 5rem;
        padding: 0 60px;
        object-fit: cover;

        @include breakpoint(md) {
            padding: 60px;
        }

        @include breakpoint(lg){
            padding: 0px 80px;
        }
    }
      
    img:last-of-type, svg:last-of-type {
        padding-left: 0;
    }

    section {
        display: flex;
        animation: swipe var(--speed) linear infinite backwards;
    }
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}
