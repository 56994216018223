@import "../../styles/index.scss";

.industry-template-solutions {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
    padding: 40px 20px;
    background-color: $white;

    @include breakpoint(sm) {
        padding: 80px 40px;
    }

    @include breakpoint(md) {
        gap: 60px;
        padding: 120px 80px;
    }

    @include breakpoint(lg) {
        gap: 80px;
        padding: 180px 110px;

    }
    &-text {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h3 {
                color: $black;
                text-transform: uppercase;
    
                @include breakpoint(lg) {
                    @include t-h3;
                }
            }
    
            @include breakpoint(md) {
                gap: 40px;
            }
        }

        &-desc {
            width: 100%;

            p {
                color: $grey;

                @include breakpoint(lg) {
                    @include t-s-18;
                }
            }

            @include breakpoint(md) {
                width: 70%;
            }
        }
    }

    &-items {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include breakpoint(md) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-item {
            display: flex;
            flex-direction: column;
            padding: 32px;
            justify-content: space-between;
            border: 1px solid $grey;
            gap: 16px;

            @include breakpoint(md) {
                padding: 56px;
                flex: 1 1 40%;

                &:last-child {
                    flex: 0 0 48.825%;
                }
            }

            @include breakpoint(lg) {
                gap: 80px;
            }

            &-info {
                display: flex;
                flex-direction: column;
                gap: 16px;

                h4 {
                    color: $black;
                    text-transform: uppercase;

                    @include breakpoint(lg) {
                        @include t-h-24;
                        white-space: break-spaces;
                    }
                }
                p {
                    color: $grey;
                    @include breakpoint(lg) {
                        @include t-s-18;
                    }
                }

            }

            &-link {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                @include breakpoint(md) {
                    justify-content: flex-start;
                }
            }
        }

    }
}