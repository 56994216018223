@import "../../styles/index.scss";

.our-articles {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: $white;
    background-color: $black;
    margin: 80px 20px;

    @include breakpoint(sm) {
        margin: 80px 30px;
    }

    @include breakpoint(md) {
        margin: 80px 50px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        max-width: 1280px;
        margin-top: 120px;
        margin-bottom: 180px;
    }

    .swiper-button-next,
    .swiper-button-previous {
        margin-top: 0;
        position: relative;
        z-index: 0;

        &.disabled {
            background-color: $grey;
            cursor: default;
        }
    }

    .one-case-link {
        color: $white;

        svg {
            path {
                fill: $white;
            }
        }
    }

    .swiper {
        margin-left: 0;
        margin-right: 0;
    }
    
    .swiper-pagination {
        gap: 8px;
        display: flex;
        margin-top: 32px;
        align-items: center;
        text-align: center;
        justify-content: center;

        display: flex;

        @include breakpoint(md) {
            display: none;
        }
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: transparent;
        border-radius: 5px;
        border: 1px solid transparent;
        background: $whiteOpacity;
        opacity: 1;
        cursor: pointer;
    }

    .swiper-pagination-bullet-active {
        width: 6px;
        height: 6px;
        transition: width 0.5s;
        border-radius: 5px;
        background: $white;
        border: 1px solid transparent;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include breakpoint(md) {
            p {
                @include t-h3;
            }
        }

        h3 {
            @include breakpoint(lg) {
                @include t-h-34;
            }
        }

        &-controls {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
    }
}
