@import "../../styles/index.scss";

.industry-template {
    display: flex;
    flex-direction: column;
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    gap: 80px;

    @include breakpoint(sm) {
        margin: 0 30px;
        margin-top: 100px;
    }

    @include breakpoint(md) {
        margin: 0 50px;
        margin-top: 160px;
        gap: 120px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        margin-top: 200px;
        max-width: 1280px;
        gap: 180px;
    }

    &-big-num-list {
        margin: 0;
        padding: 40px;

        @include breakpoint(md) {
            padding: 120px 90px 100px 320px;
        }

        @include breakpoint(lg) {
            padding: 180px 109px 180px 326px;
        }
    }

    .our-testimonials {
        margin-top: 0;
        margin-bottom: 0;

        @include breakpoint(sm) {
            margin: 0;
        }
    }

    .industry-intro {
        margin: 0px 20px 80px 20px;

        @include breakpoint(sm) {
            margin: 0 0 80px 0;
        }

        @include breakpoint(md) {
            margin-bottom: 120px;
        }

        @include breakpoint(lg) {
            margin-bottom: 180px;
        }
    }



    .technologies-list-icons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 32px;

        @include breakpoint(lg) {
            gap: 40px;
        }

        &-container-title {
            background-color: $white;
            color: $black;
        }
    }
}
