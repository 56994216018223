@import "../../styles/index.scss";

.service-template-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 20px;

    @include breakpoint(sm) {
        margin: 0;
    }
    @include breakpoint(lg) {
        gap: 16px;
        p {
            font-family: "MacPaw Fixel";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }
    }
    
    &-desc {
        color: $lightGrey;

        @include breakpoint(sm) {
            width: 60%;
        }
    }
    h1 {
        color: $white;
        font-family: "PP Telegraf";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        text-transform: uppercase;

        @include breakpoint(sm) {
            @include t-h1;
        }
    }

    &-cta {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding-top: 40px;
        text-transform: uppercase;
        align-items: center;
        width: max-content; 
        cursor: pointer;
        padding-bottom: 40px;

        @include breakpoint(md){
            padding-top: 60px;
            padding-bottom: 100px;
        }

        @include breakpoint(lg) {
            gap: 12px;
            padding-top: 70px;
            padding-bottom: 120px;

            a {
                @include t-s-18;
            }

            svg {
                width: 24px;
            }
        }
    }

}