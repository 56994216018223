@import "../../styles/index.scss";

.cases-wrapper {
    background-color: $white;
    padding: 40px 20px;

    @include breakpoint(sm) {
        padding: 60px 40px;
    }

    @include breakpoint(md) {
        padding: 80px;
    }

    @include breakpoint(lg) {
        padding: 108px;
    }
}

.our-cases {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        
        h3 {
            margin-bottom: 40px;
            @include breakpoint(lg) {
                margin-bottom: 60px;
                @include t-h-34;
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        @include breakpoint(md) {
            gap: 60px;
        }
    }

    &-title {
        @include breakpoint(xs) {
            @include t-s3;
        }

        @include breakpoint(md) {
            @include t-h1;
        }
    }
}
