// class 't-h1' - h1
.t-h1 {
    font-family: "PP Telegraf";
    line-height: 130%;
    font-size: 46px;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: uppercase;
}

// class 't-h1-m' - h1 mobile
.t-h1-m {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
}

// class 't-h2' - h2
.t-h2 {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 46px;
    font-weight: normal;
}

// class 't-h2-m' - h2 mobile
.t-h2-m {
    font-family: "PP Hatton";
    letter-spacing: 0;
    font-size: 28px;
    font-weight: normal;
}

// class 't-h3' - h3
.t-h3 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 34px;
    font-weight: normal;
}

// class 't-s1' - subtitle 1
.t-s1 {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 34px;
    font-weight: normal;
}

// class 't-s1-m' - subtitle 1 mobile
.t-s1-m {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 30px;
    font-weight: normal;
}

// class 't-s2' - subtitle 2
.t-s2 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 32px;
    font-weight: normal;
}

// class 't-s2-m' - subtitle 2 mobile
.t-s2-m {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 24px;
    font-weight: normal;
}

// class 't-s3' - subtitle 3
.t-s3 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 22px;
    font-weight: normal;
    text-transform: uppercase;
}

// class 't-s4' - subtitle 4
.t-s4 {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 23px;
    font-weight: normal;
}

// class 't-s5' - subtitle 5
.t-s5 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
}

// class 't-s6' - subtitle 6
.t-s6 {
    font-family: "MacPaw Fixel";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 500;
}

// class 't-b1' - body 1
.t-b1 {
    font-family: "MacPaw Fixel";
    line-height: 140%;
    letter-spacing: 0;
    font-size: 24px;
    font-weight: normal;
}

// class 't-b2' - body 2
.t-b2 {
    font-family: "MacPaw Fixel";
    line-height: 180%;
    letter-spacing: 4%;
    font-size: 24px;
    font-weight: 300;
}

// class 't-b3' - body 3
.t-b3 {
    font-family: "MacPaw Fixel";
    line-height: 160%;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: normal;
}

// class 't-b4' - body 4
.t-b4 {
    font-family: "MacPaw Fixel";
    line-height: 160%;
    letter-spacing: 0;
    font-size: 14px;
    font-weight: normal;
}

.t-nav {
    font-size: 18px;
    font-family: "MacPaw Fixel";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.t-link {
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-transform: none;
}

.t-ns {
    font-family: "New York Plain";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

// EXTENDS

// class 't-h1' - h1
@mixin t-h1 {
    font-family: "PP Telegraf";
    line-height: 130%;
    font-size: 46px;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: uppercase;
}

// class 't-h1-m' - h1 mobile
@mixin t-h1-m {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 28px;
    font-weight: normal;
    text-transform: uppercase;
}

// class 't-h2' - h2
@mixin t-h2 {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 46px;
    font-weight: normal;
}

// class 't-h2-m' - h2 mobile
@mixin t-h2-m {
    font-family: "PP Hatton";
    letter-spacing: 0;
    font-size: 28px;
    font-weight: normal;
}

// class 't-h3' - h3
@mixin t-h3 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 34px;
    font-weight: normal;
}

// class 't-s1' - subtitle 1
@mixin t-s1 {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 34px;
    font-weight: normal;
}

// class 't-s1-m' - subtitle 1 mobile
@mixin t-s1-m {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 30px;
    font-weight: normal;
}

// class 't-s2' - subtitle 2
@mixin t-s2 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 32px;
    font-weight: normal;
}

// class 't-s2-m' - subtitle 2 mobile
@mixin t-s2-m {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 24px;
    font-weight: normal;
}

// class 't-s3' - subtitle 3
@mixin t-s3 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 22px;
    font-weight: normal;
    text-transform: uppercase;
}

// class 't-s4' - subtitle 4
@mixin t-s4 {
    font-family: "PP Hatton";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 23px;
    font-weight: normal;
}

// class 't-s5' - subtitle 5
@mixin t-s5 {
    font-family: "PP Telegraf";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
}

// class 't-s6' - subtitle 6
@mixin t-s6 {
    font-family: "MacPaw Fixel";
    line-height: 130%;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 500;
}

// class 't-b1' - body 1
@mixin t-b1 {
    font-family: "MacPaw Fixel";
    line-height: 140%;
    letter-spacing: 0;
    font-size: 24px;
    font-weight: normal;
}

// class 't-b2' - body 2
@mixin t-b2 {
    font-family: "MacPaw Fixel";
    line-height: 180%;
    letter-spacing: 4%;
    font-size: 24px;
    font-weight: 300;
}

// class 't-b3' - body 3
@mixin t-b3 {
    font-family: "MacPaw Fixel";
    line-height: 160%;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: normal;
}

// class 't-b4' - body 4
@mixin t-b4 {
    font-family: "MacPaw Fixel";
    line-height: 160%;
    letter-spacing: 0;
    font-size: 14px;
    font-weight: normal;
}

// class 't-nav' - body 4
@mixin t-nav {
    font-size: 18px;
    font-family: "MacPaw Fixel";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

@mixin t-link {
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-transform: none;
}

@mixin t-ns {
    font-family: "New York Plain";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


// NEW //

.t-h-34 {
    font-family: "PP Telegraf";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

.t-h-24 {
    font-family: "PP Telegraf";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

.t-h-m-22 {
    font-family: "PP Telegraf";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

.t-h-m-20 {
    font-family: "PP Telegraf";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

.t-n-m-20 {
    font-family: "PP Hatton";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
}

.t-h-16{
    font-family: "PP Telegraf";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    text-transform: uppercase;
}

.t-h-18 {
    /* Desktop/Heading/HEADING | 18 */
    font-family: "PP Telegraf";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    text-transform: uppercase;
}

.t-h-m-14 {
    font-family: "PP Telegraf";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    text-transform: uppercase;
}

.t-h-m-16 {
    font-family: "PP Telegraf";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    text-transform: uppercase;
}

.t-l-10 {
    font-family: "MacPaw Fixel";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 16px */
}

.t-l-12 {
    font-family: "MacPaw Fixel";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 16px */
}

.t-s-12 {
    font-family: "MacPaw Fixel";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
}

.t-s-14 {
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
}

.t-s-16 {
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.t-s-18 {
    font-family: "MacPaw Fixel";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
}

.t-s-24 {
    font-family: "MacPaw Fixel";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */
}

@mixin t-s-12 {
    font-family: "MacPaw Fixel";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
}

@mixin t-s-14 {
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
}

@mixin t-s-16 {
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
}

@mixin t-s-18 {
    font-family: "MacPaw Fixel";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
}

@mixin t-s-24 {
    font-family: "MacPaw Fixel";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */
}

@mixin t-l-10 {
    font-family: "MacPaw Fixel";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 16px */
}

@mixin t-l-12 {
    font-family: "MacPaw Fixel";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 16px */
}

@mixin t-h-34 {
    font-family: "PP Telegraf";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

@mixin t-h-24 {
    font-family: "PP Telegraf";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

@mixin t-h-m-22 {
    font-family: "PP Telegraf";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

@mixin t-h-m-20 {
    font-family: "PP Telegraf";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    text-transform: uppercase;
}

@mixin t-n-m-20 {
    font-family: "PP Hatton";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
}

// class 't-s5' - subtitle 5
@mixin t-h-16{
    font-family: "PP Telegraf";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    text-transform: uppercase;
}

@mixin t-h-18 {
    /* Desktop/Heading/HEADING | 18 */
    font-family: "PP Telegraf";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    text-transform: uppercase;
}

@mixin t-h-m-14 {
    font-family: "PP Telegraf";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    text-transform: uppercase;
}

@mixin t-h-m-16 {
    font-family: "PP Telegraf";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    text-transform: uppercase;
}