@import "../../styles/index.scss";
 
.one-article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $whiteOpacity;
    padding: 24px;
    height: 430px;

    @include breakpoint(md) {
        padding: 40px;
        height: 500px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: $white;

        h3 {
            @include breakpoint(md) {
                @include t-h-24;
            }
        }

        p {
            color: $whiteOpacity;
            @include breakpoint(lg) {
                @include t-s-16;
            }
        }
    }

    &-info {
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        border-top: 1px solid $whiteOpacity;

        p {
            color: $whiteOpacity;
            @include breakpoint(md) {
                @include t-b3;
            }
        }

        svg {
            margin-top: -8px;
        }
    }
}