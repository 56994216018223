@import "../../styles/index.scss";

.people-card {

    @include breakpoint(md) {
        width: 50%;
    }

    @include breakpoint(lg) {
        width: auto;
    }
    &-image {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: auto;
            max-width: 402px;

            @include breakpoint(sm) {
                max-width: 350px;
            }

            @include breakpoint (md) {
                width: 420px;
                max-width: max-content;
            }
            @include breakpoint(lg) {
                width: 402px;
                max-width: 350px;
            }
        }
        h4 {
            margin-top: 24px;
            color: $black;
            @include breakpoint(md) {
                @include t-s1;
            }
        }
        h5 {
           color: $grey;
        }
        hr {
            width: 21px;
            background-color: $grey;
            border: none;
            height: 1px;
            margin-left: 0;
        }

        &-linkedin {
            text-decoration: underline;
            color: $grey;
            @include t-link;
        }
    }
}
