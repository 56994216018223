@import "../../styles/index.scss";

.header {
    width: 100%;
    background: $black;
    z-index: 10;
    position: absolute;

    &.show {
      position: fixed;
    }

    &.hide {
      display: none;
    }

  &-navigation {
    padding: 24px 20px 24px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: none;

    @include breakpoint(sm) {
      padding: 24px 30px 24px 30px;
    }

    @include breakpoint(md) {
      padding: 32px 50px;
    }

    @include breakpoint(lg) {
      margin: 0 auto;
      padding: 32px 0px;
      max-width: 1280px;  
    }

    &-logo {
      color: $white;
      width: 70%;
      z-index: 10;
      margin: auto 0;

      @include breakpoint(sm) {
        width: 30%;
      }

      @include breakpoint(lg) {
        width: 35%;
      }

      img {
            border-radius: 50%;
            box-shadow: 0 0 2rem rgba(236, 236, 236, 0.5);
            width: 70px;
            height: 70px;
            z-index: 10;
            margin: 10px;

            @include breakpoint(lg) {
              width: 80px;
              height: 80px;
              margin: 15px;
            }
        }
    }
    &-desktop {
        display: flex;
        width: 70%;
        float: right;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        align-items: center;  
        padding: 10px 0px;

        @include breakpoint(lg) {
          width: 65%;
        }
        
        a {
            color: $lightGrey;
            cursor: pointer;

            @include breakpoint(lg) {
              @include t-s-16;
            }
        }

        &-items {
          display: inline-block;
          text-transform: uppercase;
          
          .items-divider {
            display: none;
          }

          p {
            @include breakpoint(lg) {
              @include t-s-16
            }
          }

          a {
            padding: 5px 0;
          }

          &:hover {
            .items-divider {
              display: block;
              border: 1px solid white;
              margin-top: -10px;
              width: auto;
            }
          }

          &-subitems {
            background-color: $black;
            overflow: hidden;
            width: 100%;
            display: flex;
            max-width: 1280px;
            gap: 48px;
            flex-direction: row;
            position: relative;

            &-subitem {
              float: left;
              box-sizing: border-box;
              display: flex;

              &:hover {
                p, .title {
                  color: $white !important;
                }

                .divider {
                  height: 100%;
                }
              } 

              p {
                @include t-s-14;
                text-transform: initial;
                padding-left: 24px;
                color: $lightGrey !important;
              }

              .title {
                padding-bottom: 16px;
                cursor: pointer;
                color: $lightGrey !important;
                text-decoration: none;
                display: block;
                text-align: left;
                padding-left: 24px;
                font-family: "PP Telegraf";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                text-transform: uppercase;

                @include breakpoint(lg) {
                  width: 110%;
                }
              }

              .divider {
                border: $white !important;
                width: 0 !important;
                border-left:  1px solid $white !important;
                height: 60px;
                margin-top: -1px !important;
                opacity: 0.7;

                transition: height 0.3s ease;

              }
            }
          }

        }
    }
  }

  &-menu {   
    border-bottom: 1px solid $white; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-list {
      margin: 0;

      &-item {
        p {
            color: $lightGrey;
            text-transform: uppercase;
            cursor: pointer;
        }

        &-dropdown {
          a {
          color: $lightGrey;
            text-transform: uppercase;
            cursor: pointer;
          }
        }

        &.active {
            p {
              color: $white;
              text-transform: uppercase;
            }

          ul {
            display: flex;
          }
        }

        ul {
            display: none;
        }

        &-dropdown {
          padding: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        &-horizontally {
          flex-direction: column !important;
          width: 100% !important;
            a {
              text-transform: uppercase;
              color: $lightGrey;
              cursor: pointer;
              padding: 12px 0px 12px 44px;
          }
        }
      }
    }
    ul {
      padding: 0;
      margin: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;     
    }
    
    &-link {
      color: $lightGrey;
      margin: 20px;
      border: 1px solid $lightGrey;
      padding: 20px 12px;
      cursor: pointer;
      text-align: center;

      a {
        text-transform: uppercase;

        @include breakpoint(lg) {
          @include t-s-18;
        }
      }

      @include breakpoint(md){
        padding: 0;
        border: none;
        margin: 0;
      }

      svg {
        margin-bottom: -6px;
        margin-left: 12px;
      }
    }
  }

  hr {
    border: 0;
    width: 100%;
    border-top: 1px solid $white;
    margin-left: 0;
    margin-right: 0;
  }

  .border-up {
    margin: 0;
  }
}

.header-navigation-desktop-items:hover > p{
  color: $white !important;
  opacity: 1;
}

@keyframes slideBottom {
  from {
    margin-top: -0.4%;
    opacity: 0.9;
  }

  to {
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    margin-left: 100%;
    width: 110%; 
    opacity: 0;
  }

  to {
    margin-left: 0%;
    width: 100%;
    opacity: 1;
  }
}

.header-navigation-desktop-items.active {
    color: $white !important;
  
  hr {
    display: block;
    border: 1px solid $white;
    margin-top: -10px;
    width: auto;
  }
}
