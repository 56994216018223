@import "../../styles/index.scss";

.terms-and-conditions {
    display:  flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 140px;
    color: $lightGrey;
    margin-bottom: 80px;

    p, li, a {
        @include t-s-14;

        @include breakpoint(lg) {
            font-family: "MacPaw Fixel";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }
    }

    strong {
        font-weight: 700;
    }

    a {
        text-decoration: underline;
        color: $lightGrey;
    }
    @include breakpoint(sm) {
        margin-top: 100px;
    }

    @include breakpoint(md) {
        margin-top: 160px;
        gap: 40px;
        margin-bottom: 120px;
    }

    @include breakpoint(lg) {
        margin: 200px 124px 180px 124px;
        gap: 60px;
    }

    h1 {
        color: $white;
        font-family: "PP Telegraf";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 36.4px */
        text-transform: uppercase;

        @include breakpoint(lg) {
            @include t-h1;
        }
    }
}
