@import "../../styles/index.scss";

.contact-us {
    display: flex;
    flex-direction: column;
    margin: 160px 0px 80px 0px;
    display: flex;
    flex-direction: column;
    gap: 80px;

    @include breakpoint(sm) {
        margin: 160px 30px 80px 30px;
    }

    @include breakpoint(md) {
        margin: 160px 50px 120px 50px;
    }

    @include breakpoint(lg) {
        margin: 210px auto 180px auto;
        gap: 120px;
        max-width: 1280px;
    }

    &-header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0 20px;

        @include breakpoint(sm) {
            margin: 0;
        }

        @include breakpoint(lg) {
            gap: 16px;
            p {
                font-family: "MacPaw Fixel";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }
        }
       
        &-desc {
            color: $lightGrey;

            @include breakpoint(sm) {
                width: 60%;
            }

            @include breakpoint(lg) {
                width: 50%;
            }
        }
        h1 {
            color: $white;
            font-family: "PP Telegraf";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: uppercase;

            @include breakpoint(sm) {
                @include t-h1;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        gap: 80px;

        @include breakpoint(md) {
            flex-direction: row;
            justify-content: space-between;

            .send-email {
                width: 50%;
            }
        }
        

        &-socials {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin: 0 20px;
    
            @include breakpoint(sm) {
                margin: 0;
            }

            @include breakpoint(md) {
                flex-direction: row;
                flex-wrap: wrap;
                width: 50%;
            }
    
            h3 {
                color: $white;
                text-transform: uppercase; 
    
                @include breakpoint(md) {
                    @include t-s-18;
                }
            }
    
            &-chat, &-visit, &-follow {
                display: flex;
                flex-direction: column;
                gap: 28px;
    
                @include breakpoint(md) {
                    gap: 32px;
                }
    
                &-links {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
    
                    a {
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
    
                        color: $lightGrey;
                        text-align: right;
                        font-family: "MacPaw Fixel";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                        text-decoration-line: underline;
    
                        @include breakpoint(md) {
                            font-size: 16px;
                        }
    
                        img {
                            width: 24px;
                            opacity: 0.7;
                        }
    
                        &:hover {
                            color: $white;
    
                            img {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
    
            &-follow {
                &-links {
                    @include breakpoint(sm) {
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
    
                    & > a {
                        flex: 1 1 40%;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
