@import "../../styles/index.scss";

.about-us {
    @include breakpoint(sm) {
        margin: 0 30px;
    }

    @include breakpoint(md) {
        margin: 0 50px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        max-width: 1280px;
    }

    &-header {
        color: $white;
        margin: 130px 20px 0;
        padding-bottom: 60px;
        border-bottom: 1px solid $white;
        h1 {
            margin-bottom: 60px;
        }

        @include breakpoint(sm) {
            margin-left: 0;
            margin-right: 0;
        }
        @include breakpoint(md) {
            margin: 153px 0 0;
            display: flex;
            align-items: flex-end;
            padding: 0 0 100px 107px;
            gap: 35px;

            h1 {
                @include t-h3;
            }
        }

        @include breakpoint(lg) {
            padding-bottom: 120px;
            h1 {
                @include t-h1;
            }
        }

        &-video {
            width: 100%;
        }
        iframe {
            width: 100%;
            height: auto;
            max-width: 995px;
            min-height: 212px;
            object-fit: cover;
            @include breakpoint(sm) {
                width: 600px;
                height: 330px;
            }
            @include breakpoint(md) {
                width: 100%;
                height: 400px;
            }
            @include breakpoint(lg) {
                height: 613px;
            }
        }
    }

    &-strengths {
        border-top: 1px solid $lightGrey;
        border-bottom: 1px solid $lightGrey;
    }

    &-activities {
        color: $white;
        margin: 0 20px 80px;
       
        @include breakpoint(sm) {
            margin: 0 0 80px;
        }

        @include breakpoint(md) {
            margin: 0 0 120px;
        }

        @include breakpoint(lg) {
            margin: 0 0 140px;
        }

        h3 {
            margin-bottom: 40px;
            @include breakpoint(md) {
                margin: 0 0 116px;
                margin-bottom: 80px;
                @include t-h3;
            }
        }

        &-desc {
            margin-top: 40px;
            color: $lightGrey;
            hr {
                border: 0;
                background-color: $lightGrey;
                width: 24px;
                height: 1px;
                margin: 16px 0;
            }

            h4 {
                color: $white;
            }

            @include breakpoint(sm) {
                max-width: 250px;
            }

            @include breakpoint(md) {
                max-width: 403px;

                h4 {
                    text-transform: unset !important;
                    color: $white;
                    @include t-s2;
                }

                p {
                    @include t-s-16;
                }

                hr {
                    margin: 24px 0;
                }
            }
        }

        &-sport {
            max-width: 375px;
            margin: 80px auto 0;
            @include breakpoint(sm) {
                display: flex;
                max-width: 1440px;
                justify-content: space-between;
                align-items: center;
            }
            &-images {
                position: relative;
                height: 287px;
                width: 343px;
                img {
                    border: 0.5px solid rgba(255, 255, 255, 0.40);;
                }
                @include breakpoint(lg) {
                    height: 634px;
                    width: 758px;
                }
                #sport-1 {
                    width: 187px;
                    position: absolute;
                    //margin-left: 41px;
                    margin-top: 32px;
                    z-index: 1;
                    @include breakpoint(lg) {
                        width: 464px;
                        //margin-left: 90px;
                        margin-top: 70px;
                    }
                }

                #sport-3 {
                    width: 140px;
                    margin-top: 40px;
                    margin-left: 160px;
                    position: absolute;
                    z-index: 3;
                    @include breakpoint(lg) {
                        width: 309px;
                        margin-left: 360px;
                        margin-top: 120px;
                    }
                }
                #sport-4 {
                    width: 128px;
                    margin-top: 160px;
                    margin-left: 210px;
                    position: absolute;
                    z-index: 3;
                    @include breakpoint(lg) {
                        width: 309px;
                        margin-top: 403px;
                        margin-left: 430px;
                    }
                }
            }
        }

        &-events {
            max-width: 375px;
            margin: 80px auto 0;
            @include breakpoint(sm) {
                display: flex;
                max-width: 1440px;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
            }
            &-images {
                position: relative;
                height: 291px;
                width: 343px;
                img {
                    border: 0.5px solid rgba(255, 255, 255, 0.40);;
                }
                @include breakpoint(lg) {
                    height: 668px;
                    width: 780px;
                }
                #events-1 {
                    width: 155px;
                    position: absolute;
                    margin-left: 180px;
                    z-index: 1;
                    @include breakpoint(lg) {
                        width: 377px;
                        margin-left: 403px;
                    }
                }

                #events-2 {
                    width: 165px;
                    margin-top: 47px;
                    position: absolute;
                    z-index: 2;
                    @include breakpoint(lg) {
                        width: 379px;
                        margin-top: 110px;
                    }
                }
                #events-3 {
                    width: 166px;
                    margin-top: 179px;
                    margin-left: 64px;
                    position: absolute;
                    z-index: 3;
                    @include breakpoint(lg) {
                        width: 381px;
                        margin-top: 414px;
                        margin-left: 139px;
                    }
                }
            }
        }

        &-teambuildings {
            max-width: 375px;
            margin: 0 auto;
            @include breakpoint(sm) {
                display: flex;
                flex-direction: row-reverse;
                max-width: 1440px;
                justify-content: space-between;
                align-items: center;
            }

            &-images {
                position: relative;
                height: 293px;
                width: 343px;
                img {
                    border: 0.5px solid rgba(255, 255, 255, 0.40);;
                }

                @include breakpoint(lg) {
                    height: 668px;
                    width: 780px;
                }
                #teambuilding-1 {
                    width: 165px;
                    position: absolute;
                    margin-left: 170px;
                    margin-top: 45px;
                    z-index: 1;
                    @include breakpoint(lg) {
                        width: 377px;
                        margin-top: 102px;
                        margin-left: 403px;
                    }
                }

                #teambuilding-2 {
                    margin-top: 134px;
                    width: 160px;
                    position: absolute;
                    z-index: 2;
                    @include breakpoint(lg) {
                        width: 379px;
                        margin-top: 304px;
                    }
                }
                #teambuilding-3 {
                    width: 165px;
                    margin-top: 2px;
                    margin-left: 65px;
                    position: absolute;
                    z-index: 3;
                    @include breakpoint(lg) {
                        width: 379px;
                        margin-left: 143px;
                    }
                }
            }
        }
    }
}
