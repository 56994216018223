@import "../../styles/index.scss";

.industry-template-header {
    position: relative;
    margin: 0 20px;

    @include breakpoint(sm) {
        margin: 0;
    }

    &-image {
        width: 100%;
    }

    &-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: max-content;

        p {
            color: $lightGrey;
            text-transform: uppercase;
            @include breakpoint(lg) {
                @include t-s-18;
            }
        }

        h1 {
            color: $white;
            @include breakpoint(lg) {
                @include t-h-34;
            }
        }

        @include breakpoint(lg) {
            gap: 24px;
        }
    }
}