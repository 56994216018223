@import "../../styles/index.scss";

.case-template-overview{
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: $black;

    @include breakpoint(md) {
        gap: 32px;

        h3 {
            @include t-h-34;
        }
    }

    @include breakpoint(lg) {
        gap: 40px;
    }

    &-desc {
        display: flex;
        flex-direction: column;
        gap: 10px;

        color: $grey;
    }
}