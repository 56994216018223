@import "../../styles/index.scss";

.newsoft-people-wrapper {
    max-width: 1440px;
    margin: 0 auto;
}

.newsoft-people {
    background-color: $light;
    margin: 80px 0;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint(sm) {
        flex-direction: row;
        justify-content: space-between;
    }

    @include breakpoint(md) {
        margin: 160px 0;
        padding: 80px;
    }

    @include breakpoint(lg) {
        margin: 180px 0;
        padding: 108px;
    }

    h3 {
        @include breakpoint(md) {
            @include t-h-34;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: flex-end;

        @include breakpoint(lg) {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 40px 24px;
        }
    }
}
