@import "../../styles/index.scss";

.our-history {
  background-color: $white;
  padding: 40px 20px;
  @include breakpoint(sm) {
    max-width: 100%;
  }

  @include breakpoint(md) {
    padding: 0;
    background-color: transparent;
  }

  h3 {
    @include breakpoint(md) {
      @include t-h3;
    }
  }

  &-title {
    color: $black;
    text-align: start;

    @include breakpoint(md) {
      color: $light;
      width: 143px;
    }
  }

  &-mobile {
    background-color: $white;
    &-item {
      padding-top: 40px;
      display: flex !important;
      flex-direction: column;
      gap: 24px;

      h4 {
        padding-bottom: 24px;
      }
      &-img {
        width: 100%;
        img {
          width: inherit;
        }
      }

      &-desc {
        &-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          &-point {
            display: flex;
            flex-direction: row;
            gap:16px;
      
            p {
              color: $grey !important;
            }
          }
      }
    }
    }
  }
}

.our-history-nav {
  color: $grey;
  align-items: center;
  display: flex;
  gap: 8px;

  svg {
    color: $grey;
  }
}

.sequence {
    position: relative;
    height: 600vh;

    @include breakpoint(lg) {
      margin: 0;
    }

  }
  .sequence.on-screen .steps .progress-bar {
    opacity: 1;
    transform: translate3d(-50%, 0, 0) scaleY(1);
  }
  .sequence.on-screen .steps .item:nth-child(1) .label {
    transition-delay: 0ms;
  }
  .sequence.on-screen .steps .item:nth-child(2) .label {
    transition-delay: 200ms;
  }
  .sequence.on-screen .steps .item:nth-child(3) .label {
    transition-delay: 400ms;
  }
  .sequence.on-screen .steps .item:nth-child(4) .label {
    transition-delay: 600ms;
  }
  .sequence.on-screen .steps .item:nth-child(5) .label {
    transition-delay: 800ms;
  }
  .sequence.on-screen .steps .item:nth-child(6) .label {
    transition-delay: 1000ms;
  }
  .sequence.on-screen .steps .item .label {
    opacity: 1;
    transform: translateY(-50%);
  }
  .sequence.on-screen .steps .item .icon-ring {
    transform: translate(-50%, -50%) scale(0);
  }
  .sequence.on-screen .steps .item .dot {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition-delay: 2000ms;
  }
  .sequence.on-screen .steps .item[data-state=current] .icon-ring {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  .sequence[data-live=true] .details {
    @include breakpoint(md) {
      left: 50%;
    }
  }
  .sequence[data-live=true]:before {
    left: 50%;
  }
  .sequence:before {
    transform: translate3d(0, 0, 0);
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .sequence .viewport {
    top: 0;
    position: absolute;
    height: 100vh;
    transform: translate3d(0, 0, 0);

    @include breakpoint(md) {
      padding-top: 40px;
    }
  }

  .sequence .viewport .container {
    height: 100%;
    position: relative;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .sequence .details {
    transform: translate3d(0, 0, 0);
    width: 100%;
    margin-left: 40%;
    background: white;
    will-change: left;
    transition-property: left;
    transition-duration: 800ms;
    transition-timing-function: ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 27px;
      
    @include breakpoint(md) {
      display: block;
      background-color: $light;
    }
  }
  .sequence .details .item {
    padding: 40px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex !important;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
    height: fit-content;

    @include breakpoint(md) {
      padding: 40px 20px;
    }

    @include breakpoint(lg) {
      padding: 60px;
      gap: 40px;
    }
  }
  .sequence .details .item[data-state=past] .img {
    z-index: 2;
  }
  .sequence .details .item[data-state=current] {
    background: $white;
    opacity: 1;
  }
  .sequence .details .item[data-state=current] .description {
    opacity: 1;
    width: 236px;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include breakpoint(sm) {
      width: 420px;
      margin-top: 320px;
    }

    @include breakpoint(md) {
      width: 100%;
      margin-top: 0px;
      gap: 16px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include breakpoint(lg) {
        gap: 12px;
      }
      &-point {
        display: flex;
        flex-direction: row;
        gap:16px;
  
        p {
          color: $grey !important;
          @include breakpoint(lg) {
            @include t-s-16;
          }
        }
  
        @include breakpoint(lg) {
          gap: 24px;
        }
      }
    }
  }
  .sequence .details .item[data-state=current] .description h4,
  .sequence .details .item[data-state=current] .description p{
    color: $light;

    @include breakpoint(md) {
      color: $dark;
      animation-duration: 500ms;
      animation-timing-function: ease-in-out;
      animation-name: fade-in-up;
      animation-fill-mode: both;
    }
  }
  .sequence .details .item[data-state=current] .description h4 {
    @include breakpoint(md) {
      animation-delay: 100ms;
    }
  }
  .sequence .details .item[data-state=current] .description hr {
    border: 0;
    height: 1px;
    background: linear-gradient(90deg, rgba(239, 239, 239, 0.00) 0%, rgba(239, 239, 239, 0.81) 15.10%, #EFEFEF 100%);

    @include breakpoint(md) {
      border: 1px solid $dark;
      height: 0px;
      animation-delay: 200ms;
    }
  }
  .sequence .details .item[data-state=current] .description div {
    @include breakpoint(md) {
      animation-delay: 300ms;
    }
  }
  .sequence .details .item[data-state=current] .img {
    z-index: 3;
    animation-name: cross-fade;
    animation-timing-function: ease-in-out;
    animation-duration: 500ms;
  }
  @keyframes cross-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 1023px) {
    .sequence .details .item h4.scroll-reveal,
  .sequence .details .item p.scroll-reveal {
      animation: unset;
      opacity: 0;
      transition-property: opacity, transform;
      transition-duration: 800ms;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      transform: translate3d(0, 30px, 0);
    }
    .sequence .details .item h4.scroll-reveal[data-revealed=true],
  .sequence .details .item p.scroll-reveal[data-revealed=true] {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    .sequence .details .item p.scroll-reveal[data-revealed=true] {
      transition-delay: 400ms;
    }
    .sequence .details .item hr.scroll-reveal {
      animation: unset;
      transform: scaleX(0);
      transform-origin: left center;
      transition-property: transform;
      transition-duration: 800ms;
      transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
      opacity: 0;
    }
    .sequence .details .item hr.scroll-reveal[data-revealed=true] {
      transition-delay: 200ms;
      transform: scaleX(1);
      opacity: 1;
    }
  }

  .sequence .details .item[data-index="0"] h4.scroll-reveal,
  .sequence .details .item[data-index="0"] p.scroll-reveal {
    animation: unset;
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transform: translate3d(0, 30px, 0);
  }
  .sequence .details .item[data-index="0"] h4.scroll-reveal[data-revealed=true],
  .sequence .details .item[data-index="0"] p.scroll-reveal[data-revealed=true] {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .sequence .details .item[data-index="0"] p.scroll-reveal[data-revealed=true] {
    transition-delay: 400ms;
  }
  .sequence .details .item[data-index="0"] hr.scroll-reveal {
    animation: unset;
    transform: scaleX(0);
    transform-origin: left center;
    transition-property: transform;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    opacity: 0;
  }
  .sequence .details .item[data-index="0"] hr.scroll-reveal[data-revealed=true] {
    transition-delay: 200ms;
    transform: scaleX(1);
    opacity: 1;
  }
  .sequence .details .item .img {
    overflow: hidden;
    width: 100%;
    margin: 0;

    @include breakpoint(md) {
      position: relative;
      background-color: $white;
    }

    @include breakpoint(lg) {
      width: 487px;
    }
  }
  .sequence .details .item .img {
    width: 100%;
    img {
      width: inherit;
    }
  }
  .sequence .details .item h4 {
    @include breakpoint(lg) {
      @include t-h-24;
    }
  }
  .sequence .details .item hr {
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: rgba(112, 131, 60, 0.3);

    @include breakpoint(md) {
      margin-bottom: 10px;
    }

    @include breakpoint(lg) {
      margin-bottom: 20px;
    }
  }
  
  @media (min-width: 1023px) {
    .sequence .details .item h4,
  .sequence .details .item p,
  .sequence .details .item .description {
      opacity: 0;
    }
  }
  .sequence .details .item .description {
    transform: translate3d(0, 0, 0);
    will-change: opacity, transform;
    transition-property: opacity;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
  }
  .sequence .details .item .description h4 {
    display: flex;
    justify-content: end;

    @include breakpoint(md) {
      justify-content: start;
    }
  }
  .sequence .steps {
    transition-property: opacity;
    transition-duration: 2000ms;
    transition-timing-function: ease-in-out;
    margin: 0 auto;
    margin-left: 30px;
    height: auto;

    @include breakpoint (md) {
      position: absolute;
      left: 0;
      top: 30px;
      bottom: 40px;
      right: 50%;  
    }
    @include breakpoint(lg) {
      top: 50px;
      bottom: 80px;
    }
  }
  .sequence .steps .items {
    top: 20%;
    bottom: 0;
    position: absolute;
    left: 50%;
  }
  .sequence .steps .item {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: width, height, background-color;
    transition-duration: 2000ms;
    transition-timing-function: ease-in;
  }
  .sequence .steps .item .dot {
    position: absolute;
    border-radius: 100%;
    width: 7px;
    height: 7px;
    background-color: #898990;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition-property: opacity, transform;
    transition-duration: 800ms;
    transition-timing-function: ease-in-out;
  }
  .sequence .steps .item .icon-ring {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #151517;
    background-size: cover;
    background-position: center;
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    width: 60px;
    height: 60px;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 41px;
    border: 1px solid $light;
  }
  .sequence .steps .item .icon-ring:after {
    content: "";
    border-radius: 100%;
  }

  .sequence .steps .item[data-state=past] .icon-ring {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.6);
  }

  .sequence .steps .item[data-state=current].text-right .label {
    animation: slide-right 0.8s ease forwards;
  }

  .sequence .steps .item[data-state=current].text-left .label {
    animation: slide-left 0.8s ease forwards;
  }

  .sequence .steps .item[data-state=current] .label-text {
    color: $light;
    opacity: 1;
  }

  @keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-20px);
    }
  }

  @keyframes slide-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(20px);
    }
  }

  .sequence .steps .item .label {
    position: absolute;
    transition-property: opacity, transform;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
    opacity: 0;
    transform: translateY(150%);
    top: 50%;
  }
  .sequence .steps .item .label-text {
    transition-property: color;
    transition-duration: 800ms;
    transition-timing-function: ease-in-out;
    color: $white;
    opacity: 0.5;
    white-space: nowrap;
  }
  .sequence .steps .item.text-right .label {
    left: 100%;
    margin-left: 60px;
    margin-top: -15px;
    animation: slide-left 0.8s ease forwards;
  }
  .sequence .steps .item.text-left .label {
    right: 100%;
    margin-right: 60px;
    margin-top: -15px;
    animation: slide-right 0.8s ease forwards;
  }
  .sequence .steps .item[data-index="0"] {
    top: 0;
  }
  .sequence .steps .item[data-index="1"] {
    top: 20%;
  }
  .sequence .steps .item[data-index="2"] {
    top: 40%;
  }
  .sequence .steps .item[data-index="3"] {
    top: 60%;
  }
  .sequence .steps .item[data-index="4"] {
    top: 80%;
  }
  .sequence .steps .item[data-index="5"] {
    top: 100%;
  }

  .sequence .steps .progress-bar {
    position: absolute;
    width: 3px;
    overflow: hidden;
    top: 0;
    bottom: 0;
    opacity: 0;
    transform-origin: 50% 0%;
    transform: translate3d(-50%, 0, 0) scaleY(0);
    transition-property: opacity, transform;
    transition-duration: 2000ms;
    transition-timing-function: ease-in-out;

    @include breakpoint(md) {
      left: 50%;
    }
  }
  .sequence .steps .progress-bar:before {
    content: "";
    position: absolute;
    height: 50px;
    top: 0;
    z-index: 3;
    background: linear-gradient(-180deg, #151517, transparent);
    width: 100%;
  }
  .sequence .steps .progress-bar:after {
    content: "";
    position: absolute;
    height: 50px;
    z-index: 3;
    background: linear-gradient(180deg, transparent, #151517);
    width: 100%;
    bottom: 0;
  }
  .sequence .steps .progress-bar-bg {
    background-color: #545459;
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
  }
  .sequence .steps .progress-bar-inner {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;

    @include breakpoint(md){
      top: 20%;
    }
  }
  .sequence .steps .progress-bar-inner:before {
    content: "";
    position: absolute;
    width: 3px;
    background-color: $light;
    bottom: 100%;
    z-index: 1;

    @include breakpoint(md) {
      height: 30%;
    }
  }
  .sequence .steps .progress-bar-progress {
    background-color: $white;
    stroke-width: 3px;
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0;
    height: 0;
  }
  .sequence .steps .progress-bar-progress:after {
    content: "";
    position: absolute;
    height: 50px;
    z-index: 3;
    background: linear-gradient(180deg, transparent, #545459);
    width: 100%;
    bottom: 0;
  }
  .sequence[data-renderer-position=start] .details .item .description {
    opacity: 0;
  }
  .sequence[data-renderer-position=start] .steps {
    opacity: 0;
  }
  .sequence[data-renderer-position=fixed] .viewport {
    position: sticky;
    top: 0;
  }
  .sequence[data-renderer-position=fixed] .steps {
    opacity: 1;
  }
  .sequence[data-renderer-position=end] .viewport {
    top: auto;
    bottom: 0;
  }
  .sequence[data-renderer-position=end] .details {
    left: 50%;
  }
  .sequence[data-renderer-position=end]:before {
    left: 50%;
  }
  .sequence[data-renderer-position=end] .steps {
    opacity: 1;
  }
  .sequence[data-scroll-direction=down] .steps {
    transition-delay: 2000ms;
  }

  @media (min-width: 1023px) {
    .item {
      display: flex;
      flex-direction: column;
    }  
    .item img {
      background-position: center;
    }
  }
  @media (max-width: 1023px) {
    .sequence {
      height: auto !important;
    }
    .sequence:before {
      left: 0 !important;
    }
    .sequence .steps .items{
      display: none;
    }
    .sequence .viewport {
      position: initial !important;
      height: auto !important;
    }
    .sequence .viewport .details {
      position: relative !important;
      z-index: 55;
      padding-bottom: 60px;
    }
    .sequence .viewport .details[data-live=true] .details {
      left: 0;
    }
    .sequence .viewport .details[data-live=true]:before {
      left: 0;
    }
  
    .sequence .viewport .details .item .description {
      opacity: 1 !important;
    }
    .sequence .viewport .details .item .description p {
      padding: 0 0 20px 0;
    }
    .sequence .viewport .details .item .img {
      opacity: 1 !important;
    }
  }
  @keyframes fade-in-up {
    0% {
      transform: translate3d(0, 10px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  