@import "../../styles/index.scss";

.case-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;
    padding: 40px 20px;
    background: $white;
    gap: 60px;

    @include breakpoint(sm) {
        margin: 0 30px;
        margin-top: 100px;
        padding: 60px 40px;
    }

    @include breakpoint(md) {
        margin: 0 50px;
        margin-top: 130px;
        padding: 60px;
        gap: 80px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        margin-top: 120px;
        max-width: 1280px;
        display: flex;
        padding: 108px;
        flex-direction: column;
        align-items: flex-start;
        gap: 120px;
    }
    .separator {
        border: none;
        background-color: $grey;
        color: $grey;
        height: 1px;
        width: 100%;
    }

    .technologies-list-icons img{
        filter: invert(0.9);
    }
}
