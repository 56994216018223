@import "../../styles/index.scss";

.industry-template-info {
   display: flex;
   flex-direction: column;
   gap: 80px;
   margin: 0 20px;

   @include breakpoint(sm) {
       margin: 0;
   }

   @include breakpoint(md) {
    flex-direction: row;
   }

   hr {
    width: 100%;
    background-color: $lightGrey;
    border: none;
    height: 1px;

    @include breakpoint(md) {
        width: 1px;
        height: auto;
    }
   }

   h3 {
    color: $white;
    text-transform: uppercase;
    @include breakpoint(lg) {
        @include t-h3;
    }
   }



   &-overview {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint(md) {
        flex: 1 1;
    }

    @include breakpoint(lg) {
        flex: 1 1 70%;
    }

    &-text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include breakpoint(lg) {
            gap: 18px;
        }

        p {
            color: $lightGrey;
            @include breakpoint(lg) {
                @include t-s-18;
            }
        }
    }

    &-cta {
        display: flex;
        flex-direction: row;
        gap: 8px;
        text-transform: uppercase;
        align-items: center;
        width: max-content; 
        cursor: pointer;

        &:hover {
            a {
                color: $white;
            }
        }

        a {
            color: $lightGrey;
        }

        @include breakpoint(lg) {
            gap: 12px;
            p {
                @include t-s-18;
            }

            svg {
                width: 24px;
            }
        }
    }
   }

   &-awards {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &-content {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @include breakpoint(lg) {
            gap: 40px;
        }

        &-clutch, &-manifest {
            display: flex;
            flex-direction: row;
            gap: 12px;
            flex-wrap: wrap;

            @include breakpoint(md) {
                flex-wrap: nowrap;
            }

            img {
                position: relative;
                @include breakpoint(md) {
                    margin-left: -58px;

                    &:first-child {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
   }
}