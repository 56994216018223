@import "../../styles/index.scss";

.home {
    &-intro, &-services, .video-wrapper, .cases-wrapper {
        margin: 0 20px;

        @include breakpoint(sm) {
            margin: 0 30px;
        }

        @include breakpoint(md) {
            margin: 0 50px;
        }

        @include breakpoint(lg) {
            margin: 0 auto;
            max-width: 1280px;
        }
    }

    &-intro {
        margin-top: 160px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        gap: 80px;

        @include breakpoint(md) {
            h1 {
                width: 75%;
            }    
        }

        @include breakpoint(lg) {
            margin-top: 210px;
            margin-bottom: 180px;
            gap: 140px;
        }

        &-title {
            color: $white;    
            h1 {
                text-align: left;
                @include t-h1-m;
                text-transform: uppercase;
    
                @include breakpoint(lg) {
                    @include t-h1;
                }
            }
    
            span {
                @include t-s1-m;
                text-transform: initial;
    
                @include breakpoint(lg) {
                    @include t-h2;
                }
            }
    
            .icons-companies {
                text-align: center;
                width: 100%;
                justify-content: center;
                gap: 40px;
    
                @include breakpoint(md) {
                    img {
                        height: 35px;
                        padding: 20px;
                    }
                }
                @include breakpoint(lg) {    
                    img {
                        height: 40px;
                        padding: 30px;
                    }
                }
            }
        }

        &-icons {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 50px;
            
            @include breakpoint(md) {
                flex-direction: row;
                gap: 0;
            }

            .icons-companies {
                text-align: center;
                float: left;
                flex-grow: 1;   
                display: flex;
                align-content: center;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 16px;
        
                @include breakpoint(md) {
                    text-align: left;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                }

                @include breakpoint(lg) {
                    gap: 30px;
                }
        
                svg {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    transform: scale(0.7);

                    @include breakpoint(md) {
                        padding: auto;
                        transform: scale(0.7);
                    }
        
                    @include breakpoint(lg) {
                        transform: scale(1);
                    }
                }
                
            }
        
            &-clutch {
                width: 100%;
                float: right;
                text-align: center;
                display: flex;
                flex-wrap: nowrap;
                align-content: space-between;
                margin: auto 0;
                justify-content: center;
                align-items: center;
                
                @include breakpoint(md) {
                    text-align: right;
                    justify-content: flex-end;
                    margin-top: 0;
                }
                svg {
                    position: relative;
                    transform: scale(0.7);
                    margin-left: -45px;

                    &:first-child {
                      margin-left: 0px;
                    }

                    @include breakpoint(lg) {
                        transform: scale(1);
                        margin-left: -30px;

                        &:first-child {
                            margin-left: 0px;
                        }
                    }

                    transition: margin-right 0.6s ease;
                    &:hover {
                        margin-right: 30px;
                    }
                }
            }
        }
    }

    &-icons {
        @extend .home-intro-icons;
        display: flex;
        margin: 80px 0;

        @include breakpoint(md) {
            margin: 120px 50px;
        }

        @include breakpoint(lg) {
            margin: 145px auto;
            max-width: 1280px;
        }

        .icons-companies {
            gap: 24px;
            justify-content: center;

            @include breakpoint(md) {
                //transform: scale(1.2);
                gap: 50px;
            }
            @include breakpoint(lg) {
                //transform: scale(1.3);
                gap: 80px;
            }
        }
    }
  
    &-services {
        color: $white;
        margin-bottom: 90px;
        
        @include breakpoint(md) {
            margin-bottom: 180px;
        }

        h1 {
            margin-left: 16px;

            @include breakpoint(md) {
                margin-left: 0px;
                @include t-h3;
            }

            @include breakpoint(lg) {
                margin-left: 106px;
            }
        }
    }
}
