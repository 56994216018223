@import "../../styles/index.scss";

.book-wrapper {
    background-color: $white;
    margin: 0;

    @include breakpoint(sm) {
        margin: 0 30px;
    }

    @include breakpoint(md) {
        margin: 0 50px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        max-width: 1280px;
    }
}

.book-call {
    padding: 40px 20px;
    display: flex;
    gap: 40px;
    flex-direction: column;
    text-align: right;

    @include breakpoint(sm) {
        align-items: center;
        flex-direction: row;
    }

    @include breakpoint(md) {
        justify-content: space-evenly;
    }

    @include breakpoint(lg) {
        justify-content: center;
        gap: 105px;

        h3 {
            @include t-s-16;
        }
    }

    @include breakpoint(lg) {
        padding: 108px 113px;
    }

    &-link {
        font-size: 18px;
        font-family: MacPaw Fixel;
        font-weight: 500;
        text-transform: uppercase;
        color: $dark;
    }

    &-header {
        text-align: start;
        @include breakpoint(sm) {
            text-align: end;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 30px;

            margin-bottom: 50px;
        }

        @include breakpoint(lg) {
            text-align: right;
            margin-top: -120px;
            gap: 70px;
            margin-bottom: 0;

            h2 {
                @include t-h-34;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint(md) {
            hr {
                width: 21px;
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            @include breakpoint(sm) {
                width: 280px;
                height: 280px;
            }

            @include breakpoint(md) {
                width: 420px;
                height: 420px;
            }

            @include breakpoint(lg) {
                width: 512px;
                height: 510px;
            }
        }

        h3 {
            margin-top: 30px;
            @include breakpoint(md) {
                @include t-s1;
                margin-top: 17px;
            }
        }

        h4 {
            color: $grey;
        }

        hr {
            width: 21px;
            background-color: $grey;
            border: none;
            height: 1px;
            margin-left: 0;
        }
    }
}

.book-mobile {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;

    @include breakpoint(sm) {
        display: none !important;
    }
}

.book-desktop {
    display: none;

    @include breakpoint(sm) {
        align-items: center;
        gap: 8px;
        white-space: nowrap;
        @include t-nav;
    }
}
