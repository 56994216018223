@import "../../styles/index.scss";

.our-testimonials {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: $white;
    background-color: $black;
    margin: 80px 20px;

    @include breakpoint(sm) {
        margin: 80px 30px;
    }

    @include breakpoint(md) {
        margin: 80px 50px;
    }

    @include breakpoint(lg) {
        margin: 0 auto;
        max-width: 1280px;
        margin-top: 120px;
        margin-bottom: 180px;
    }

    .swiper-button-next,
    .swiper-button-previous {
        margin-top: 0;
        position: relative;
        z-index: 0;

        &.disabled {
            background-color: $grey;
            cursor: default;
        }
    }

    .one-case-link {
        color: $white;

        svg {
            path {
                fill: $white;
            }
        }
    }

    .swiper {
        margin-left: 0;
        margin-right: 0;
        position: relative;
    }
    
    .swiper-pagination {
        gap: 8px;
        display: flex;
        margin-top: 32px;
        align-items: center;
        text-align: center;
        justify-content: center;

        display: flex;

        @include breakpoint(md) {
            display: none;
        }
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: transparent;
        border-radius: 5px;
        border: 1px solid transparent;
        background: $whiteOpacity;
        opacity: 1;
        cursor: pointer;
    }

    .swiper-pagination-bullet-active {
        width: 6px;
        height: 6px;
        transition: width 0.5s;
        border-radius: 5px;
        background: $white;
        border: 1px solid transparent;
    }

    @include breakpoint(sm) {
        .swiper {
            position: relative;
        }
        
        .swiper::before,
        .swiper::after {
            background: linear-gradient(to right, #0E0E0E 0%, rgba(255, 255, 255, 0) 120%);
            content: "";
            height: 100%;
            position: absolute;
            width: 150px;
            z-index: 2;

            @include breakpoint(lg) {
                width: 200px;
            }
        }
        
        .swiper::before {
            left: -1px;
            top: 0;
            display: none;
        }
        
        .swiper::after {
            right: -1px;
            top: 0;
            transform: rotateZ(180deg);
            display: none;
        }
        
        .swiper:has(.swiper-slide-prev)::before {
            display: block;
        }
        
        .swiper:has(.swiper-slide-next)::after {
            display: block;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include breakpoint(md) {
            p {
                @include t-h3;
            }
        }

        h3 {
            @include breakpoint(lg) {
                @include t-h-34;
            }
        }

        &-controls {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
    }
}
