// PP Telegraf
@font-face {
    font-family: 'PP Telegraf';
    src: local('PP Telegraf Regular'), local('PPTelegraf-Regular'),
        url('../assets/fonts/PPTelegraf/PPTelegraf-Regular.otf'),
        url('../assets/fonts/PPTelegraf/PPTelegraf-Regular.ttf') format('truetype'),
        url('../assets/fonts/PPTelegraf/PPTelegraf-Regular.woff2') format('woff2'),
        url('../assets/fonts/PPTelegraf/PPTelegraf-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Telegraf';
    src: local('PP Telegraf UltraLight'), local('PPTelegraf-UltraLight'),
        url('../assets/fonts/PPTelegraf/PPTelegraf-UltraLight.otf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

// PP Hatton
@font-face {
    font-family: 'PP Hatton';
    src: local('PP Hatton Regular'), local('PPHatton-Regular'),
        url('../assets/fonts/PPHatton/PPHatton-Regular.otf'),
        url('../assets/fonts/PPHatton/PPHatton-Regular.ttf') format('truetype'),
        url('../assets/fonts/PPHatton/PPHatton-Regular.woff2') format('woff2'),
        url('../assets/fonts/PPHatton/PPHatton-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// MacPaw Fixel
@font-face {
    font-family: 'MacPaw Fixel';
    src: local('MacPaw Fixel Medium'), local('MacPaw Fixel-Medium'),
        url('../assets/fonts/MacPawFixel/FixelText-Medium.ttf') format('truetype'),
        url('../assets/fonts/MacPawFixel/FixelText-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MacPaw Fixel';
    src: local('MacPaw Fixel Light'), local('MacPaw Fixel-Light'),
        url('../assets/fonts/MacPawFixel/FixelText-Light.ttf') format('truetype'),
        url('../assets/fonts/MacPawFixel/FixelText-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MacPaw Fixel';
    src: local('MacPaw Fixel Semibold'), local('MacPaw Fixel-Semibold'),
        url('../assets/fonts/MacPawFixel/FixelText-SemiBold.ttf') format('truetype'),
        url('../assets/fonts/MacPawFixel/FixelText-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MacPaw Fixel';
    src: local('MacPaw Fixel Regular'), local('MacPaw Fixel-Regular'),
        url('../assets/fonts/MacPawFixel/FixelText-Regular.ttf') format('truetype'),
        url('../assets/fonts/MacPawFixel/FixelText-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// NewYork
@font-face {
    font-family: 'New York Plain';
    src: local('New York Plain'),
        url('../assets/fonts/NewYork/NewYorkPlain.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}