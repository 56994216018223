@import "../../styles/index.scss";

.cases {
    display: flex;
    flex-direction: column;
    margin: 160px 0px 80px 0px;

    @include breakpoint(lg) {
        margin: 210px 0px 180px 0px;
    }


    &-header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        padding-bottom: 61px;

        @include breakpoint(lg) {
            padding-bottom: 91;
        }
       
        p {
            color: $whiteOpacity;

            @include breakpoint(sm) {
                width: 60%;
            }

            @include breakpoint(md) {
                font-family: "MacPaw Fixel";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 32px */
            }

            @include breakpoint(lg) {
                width: 50%;
            }
        }
        h1 {
            color: $white;
            font-family: "PP Telegraf";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: uppercase;

            @include breakpoint(sm) {
                @include t-h1;
            }
        }
    }

    &-filters {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 33px;

        @include breakpoint(sm){
            flex-direction: row;
            gap: 24px;
            align-items: center;
        }

        @include breakpoint(md) {
            gap: 32px;
        }

        &-industry {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;

            @include breakpoint(sm) {
                width: auto;
                justify-content: flex-start;
                gap: 24px;
            }

            &-clear {
                cursor: pointer;
                color: $lightGrey;
                text-decoration: underline;
                @include breakpoint(md) {
                    @include t-s-14;
                }
    
                &:hover {
                    color: $white;
                }
            }
        }
    }
    &-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        @include breakpoint(md) {
            gap: 60px;

            .one-case-image img {
                height: 350px;
            }
        }

        @include breakpoint(lg) {
            .one-case-image img {
                width: 519px;
                height: 350px;
            }
        }
    }
    &-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        @include breakpoint(md) {
            gap: 24px;
        }

        &-text {
            text-align: center;
            padding-top: 55px;

            @include breakpoint(lg) {
                padding-top: 127px;
            }

            p {
                text-transform: uppercase;
                color: $white;
    
                @include breakpoint(lg) {
                    @include t-s-18;
                }
            }
        }

        &-button {
            display: inline-flex;
            padding: 12px 20px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: 1px solid $lightGrey;
            cursor: pointer;

            &:hover {
                border: 1px solid $white;

                p, path{
                    color: $white;
                    stroke: $white;
                }

            }

            p {
                color: $lightGrey;

                @include breakpoint(lg) {
                    @include t-s-14;
                }
            }
        }
    }
}