@import "../../styles/index.scss";

.industry-intro {
    position: relative;
    border: 1px solid $lightGrey;
    padding: 20px;
    min-height: 350px;

    &-image {
        width: 100%;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
            
        gap: 24px;

        &-info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: max-content;

            p {
                color: $lightGrey;
                text-transform: uppercase;
                @include breakpoint(lg) {
                    @include t-s-18;
                }
            }

            h2 {
                color: $white;
                @include breakpoint(lg) {
                    @include t-h-34;
                }
            }

            @include breakpoint(lg) {
                gap: 24px;
            }
        }

        &-link {
            cursor: pointer;
            border: 1px solid $white;
            padding: 8px 16px;

            @include breakpoint(lg) {
                padding: 12px 24px;
            }
            p {
                color: $white;
                text-transform: uppercase;

                @include breakpoint(lg) {
                    @include t-s-14;
                }
            }

            &:hover {
                border: 1px solid $lightGrey;
                p {
                    color: $lightGrey;
                }
            }
        }
    }
}