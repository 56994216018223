@import "../../styles/index.scss";

.services {
    display: flex;
    flex-direction: column;
    margin: 160px 0px 80px 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint(md) {
        margin: 160px 0px 120px 0px;
    }

    @include breakpoint(lg) {
        margin: 210px 0px 180px 0px;
        gap: 120px;
    }


    &-header {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include breakpoint(lg) {
            gap: 16px;
            p {
                font-family: "MacPaw Fixel";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }
        }
       
        &-desc {
            color: $lightGrey;

            @include breakpoint(sm) {
                width: 60%;
            }

            @include breakpoint(lg) {
                width: 50%;
            }
        }
        h1 {
            color: $white;
            font-family: "PP Telegraf";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: uppercase;

            @include breakpoint(sm) {
                @include t-h1;
            }
        }

        &-cta {
            display: flex;
            flex-direction: row;
            gap: 8px;
            padding-top: 40px;
            text-transform: uppercase;
            align-items: center;
            width: max-content; 
            cursor: pointer;

            @include breakpoint(md){
                padding-top: 60px;
            }

            @include breakpoint(lg) {
                gap: 12px;
                padding-top: 88px;

                a {
                    @include t-s-18;
                }

                svg {
                    width: 24px;
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 80px;

        &-card {
            border: 1px solid $lightGrey;
            display: flex;
            flex-direction: column;
            //gap: 48px;
            width: 100%;
            z-index: 2;

            &:hover {
                cursor: pointer;
                background-color: #262626;
            }

            @include breakpoint(md) {
                flex-direction: row;
            }
        
            &-image {
                width: 100%;
                height: 250px;
                object-fit: cover;
                border-bottom: 1px solid $lightGrey;
                z-index: 1;

                @include breakpoint(sm) {
                    height: 350px;
                }
        
                @include breakpoint(md) {
                    height: auto;
                    width: 50%;
                    border-bottom: none;
                    border-right: 1px solid $lightGrey;
                }
        
                @include breakpoint(lg) {
                    height: 400px;
                    width: 40%;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                gap: 56px;
                padding: 40px 24px;

                @include breakpoint(md) {
                    justify-content: space-between;
                    padding: 40px;
                }
                &-info {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    @include breakpoint(lg) {
                        gap: 40px;
                    }

                    &-header {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        h3 {
                            color: $white;
                            text-transform: uppercase;

                            @include breakpoint(md) {
                                @include t-h-24;
                            }
                        }

                        p {
                            color: $lightGrey;
                            @include breakpoint(md) {
                                @include t-s-16;
                            }
                        }
                    }

                    &-topics {
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
                        flex-wrap: wrap;

                        p {
                            color: $lightGrey;
                            padding: 8px 12px;
                            border-radius: 50px;
                            border: 1px solid $lightGrey;

                            @include breakpoint(md) {
                                padding: 8px 16px;
                                @include t-s-16;
                            }
                        }
                    }
                }

                &-link {
                    display: flex;
                    flex-direction: row;
                    gap: 12px;
                    text-transform: uppercase;
                    align-items: center;
                    width: max-content;
                    cursor: pointer;
                    z-index: 3;
                }
            }
        } 
    }
}